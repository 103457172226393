import {QRActionType} from "./QRActionTypes";
import {Location} from "./Location";

export interface JobTicketQRInformation {
    companyId: string;
    companySjpp: string;
    jobSiteAddress: Location;
    qrCodeId: string;
    expiryTime: string;
    currentInstant: string;
    zoneId: string;
}

export enum QRManageError {
    loadingError = 'loadError',
    submissionError = 'submissionError',
    dispatchError = 'dispatchNotFoundError',
    noIdFoundError = 'noIdFoundError',
    cantFindWorkedShiftError = 'cantLocateWorkedShift',
    qrExpired = 'qrCodeExpired',
    locationNotProvided = 'locationNotProvided'
}