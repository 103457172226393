import styled from 'styled-components';
import {Input, withStyles} from '@material-ui/core';
import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';

interface IMobileDisplay {
    isMobile?: boolean;
    isErrorDisplay?: boolean;
}

export const InputContainer = styled.div<IMobileDisplay>`
    margin-bottom: 5px;
    min-height: ${props => props.isErrorDisplay ? '70px' : '55px'};
    height: ${props => props.isMobile ? 'auto' : '55px'};
`;

export const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TextInput = styled<typeof Input>(props =>
    <Input {...props} classes={{input: 'input'}}/>
)`
    && {
        width: ${props => props.theme.isMobile ? '100%' : '300px'};
        margin-right: ${props => props.theme.isMobile ? '0' : '10px'};
        border: 1px solid;
        border-color: ${props => props.theme.errorPassword ? '#FF0000' : 'rgba(0, 0, 0, 0.23)'};
        border-radius: 5px;
        .input  {
            color: ${props => props.theme.errorPassword ? '#ff0000' : 'inherit'};
        }
    }
`;

export const IconButtonStyled = withStyles({
    root: {
        padding: '5px',
    }
})(IconButton);

export const ErrorMessage = styled.p`
    color: #FF0000;
    font-size: 14px;
    margin-bottom: 10px;
`;

export const PasswordRulesList = styled.ul`
    list-style-type: none;
    margin: 20px 0 15px 5px;
    padding: 0;
`;

export const ListElement = styled.li`
    font-size: 14px;
    text-indent: -5px;
    
    &:before {
        content: '-';
        text-indent: -5px;
        margin-right: 5px;
    }
`;

export const InvalidRule = {
    color: 'red'
};

export const InvalidIcon = {
    color: '#FF0000',
    height: '26px',
    width: '26px'
};
