// xxx copied from exchange ordering branch FEO-33 (yet to be merged)
import _ from 'lodash';

export class LazyLoaderUtil {

    /***
     *
     * @param url - url to add as a script tag
     * @param {boolean} defer
     */
    public static addScriptTagToHead(url, defer?: boolean) {

        const headTag = document.getElementsByTagName('head')[0];

        if(!headTag) {
            console.error('No head tag found to inject script into');
            return;
        }

        // check if the same script tag exists within the head tags
        const scriptTags = headTag.getElementsByTagName('script');
        const alreadyInsertedTag = _.find(scriptTags, script => script.src === url);

        if(alreadyInsertedTag) {
            console.log(`script tag with url: ${url} has already been inserted into head tags, not adding duplicate`);
            return;
        }

        // create the element
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        defer && (scriptElement.defer = true);
        scriptElement.src = url;

        // if currently no elements just insert it into the head tags
        if (!scriptTags.length) {
            headTag.appendChild(scriptElement);
            return;
        }

        // if there is an element insert it before the first script tag found
        const firstElement = scriptTags[0];
        firstElement.parentNode.insertBefore(scriptElement, firstElement);
    }

}