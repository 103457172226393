import React from 'react';
import './styles.scss';
import { Button, Input } from '@material-ui/core';
import { RegistrationStore } from '../../stores/RegistrationStore';
import { resources } from '../../resources/strings';
import TextField from '@material-ui/core/TextField/TextField';
import InputMask from 'react-input-mask';
import { observer } from 'mobx-react';
import PrivacyPolicyDisclaimer from '../PrivacyPolicyDisclaimer';
import { LoaderOverlay } from '@swipejobs/frontend-react-core-web-components';
import { stripPhoneNumberFormatting } from '../../utils/StripPhoneNumberFormatting';
import InformationalModal from '../Modals/Base/InformationalModal';
import PrivacyPolicyModal from '../Modals/PrivacyPolicyModal';
import { WorkerRegistrationStore } from '../../stores/WorkerRegistrationStore';
import { ClientTrackingNames } from '../../models/ClientTracking';

interface IProps {
    store: RegistrationStore;
    workerRegistrationStore: WorkerRegistrationStore;

}

interface IState {
    isValid: boolean;
}

@observer
class Register extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isValid: false
        };
    }

    private isValid() {
        const isValid = !!this.props.store.firstName &&
            !!this.props.store.lastName &&
            !!this.props.store.email &&
            !!this.props.store.cellNumber &&
            stripPhoneNumberFormatting(this.props.store.cellNumber).length === 10;
        this.setState({ isValid });
    }


    componentDidMount() {
        this.props.store.clientTracking.trackRender(ClientTrackingNames.Register);
        this.isValid();
    }

    render() {
        const strings = resources.registration;
        this.props.workerRegistrationStore.hideBackBtn();

        const firstNameChanged = (event) => {
            const value = event?.target?.value;
            this.props.store.updateFirstname(value);
            this.isValid();
        };

        const lastNameChanged = (event) => {
            const value = event?.target?.value;
            this.props.store.updateLastName(value);
            this.isValid();
        };

        const emailChanged = (event) => {
            const value = event?.target?.value;
            this.props.store.updateEmail(value);
            this.isValid();
        };

        const phoneNameChanged = event => {
            const value = event?.target?.value;
            this.props.store.updateCellNumber(value);
            this.isValid();
        };

        return (
            <div id='register'
                 className='registration'>
                <LoaderOverlay isLoading={this.props.store.isLoading} />
                <div className='content'>
                    <span className='hint-text'>{strings.description}</span>
                    <span className='sub-title'>{strings.firstNameLabel}</span>
                    <TextField className='outlined-basic'
                               placeholder={strings.firstNameLabel}
                               variant='outlined'
                               value={this.props.store.firstName}
                               onChange={firstNameChanged}/>
                    <span className='sub-title'>{strings.lastNameLabel}</span>
                    <TextField className='outlined-basic'
                               placeholder={strings.lastNameLabel}
                               variant='outlined'
                               value={this.props.store.lastName}
                               onChange={lastNameChanged}/>
                    <span className='info-label'>{strings.appearsCheckLabel}</span>
                    <span className='sub-title'>{strings.emailLabel}</span>
                    <TextField className='outlined-basic'
                               placeholder={strings.emailLabel}
                               variant='outlined'
                               value={this.props.store.email}
                               onChange={emailChanged}/>
                    <span className='sub-title'>{strings.cellNumberLabel}</span>
                    <InputMask mask='(999) 999 9999'
                               value={this.props.store.cellNumber}
                               onChange={phoneNameChanged}>
                        { () => <TextField className='outlined-basic'
                                           placeholder={strings.phoneHint}
                                           variant='outlined'/>}
                    </InputMask>
                    <span className='info-label validate-label'>{strings.validateLabel}</span>
                    <PrivacyPolicyDisclaimer disclaimerText={strings.disclaimer}/>
                </div>
                <div className='footer'>
                    <Button className='button'
                            disabled={!this.state.isValid}
                            onClick={this.props.store.submitClicked}>
                        {strings.buttonLabel}
                    </Button>
                </div>
                <InformationalModal isOpen={this.props.store.isModalOpen}
                                    onClose={this.props.store.closeModal}
                                    title={this.props.store.modalTitle}
                                    content={this.props.store.modalBody} />
            </div>
        );
    }
}

export default Register;
