import React from 'react';
import parse from 'html-react-parser';
import { DomElement } from 'domhandler';
import {
    isEqual,
    isEmpty,
} from 'lodash-es';

interface IReplacement {
    type?: string;
    name?: string;
    attributes?: { [key: string]: string };
    replacement: (node: DomElement) => React.ReactNode;
}

export type ReplacementMap = { [id: string]: IReplacement };

interface IProps {
    htmlText: string;
    className: string;
    replacements: ReplacementMap;
}

function ParsedLabel(props: IProps) {
    function buildReplacementLocator(currentNode: DomElement): (id: string) => boolean {
        const { type, attribs, name } = currentNode;
        return (id: string) => {
            const replacementItem = props.replacements[id];
            const checkType = !!replacementItem.type;
            const checkName = !!replacementItem.name;
            const checkAttributes = !isEmpty(replacementItem.attributes);

            const satisfiesRules = (
                ((checkType && type === replacementItem.type) || !checkType) &&
                ((checkName && name === replacementItem.name) || !checkName) &&
                ((checkAttributes && isEqual(attribs, replacementItem.attributes)) || !checkAttributes)
            );

            // id is assumed to be an exact match
            if (attribs?.id === id) {
                return true;
            }

            return satisfiesRules;
        };
    }

    return (
        <span className={props.className}>
            {
                parse(props.htmlText, {
                    replace: (domNode) => {
                        const replacementId = Object.keys(props.replacements).find(buildReplacementLocator(domNode));

                        if (replacementId) {
                            return props.replacements[replacementId].replacement(domNode);
                        }

                        return null;
                    }
                })
            }
        </span>
    );
}

export default ParsedLabel;
