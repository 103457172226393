import React from 'react';
import { Button, Input, TextField } from '@material-ui/core';
import { PinCodeStore } from '../../stores/PinCodeStore';
import { resources } from '../../resources/strings';
import { observer } from 'mobx-react';
import history from '../../services/history';
import { PhoneFormatterUtil } from '../../utils/phoneFormatter';
import './styles.scss';
import InputMask from 'react-input-mask';
import { stripPhoneNumberFormatting } from '../../utils/StripPhoneNumberFormatting';

interface IProps {
    store: PinCodeStore;
    submitClicked: boolean;
}

interface IState {
    isValid: boolean;
}

const strings = resources.pinCode;

@observer
class PINInput extends React.Component<IProps, IState> {
    private readonly phoneNumber: string;

    constructor(props) {
        super(props);
        this.phoneNumber = props.store.pinCodeModel?.phoneNumber;

        this.state = {
            isValid: true
        }

        if (!this.phoneNumber) {
            history.push('/');
        }
    }

    render() {
        const isValid = !!this.props.store.pinCode && stripPhoneNumberFormatting(this.props.store.pinCode).length === 6;

        if (this.props.submitClicked && this.state.isValid !== isValid) {
            this.setState({ isValid });
        }

        const updatePinCode = (event) => {
            this.props.store.pinCode = event?.target?.value;
        };
        return(
            <div className='pin-input'>
                <div className='instructions'>
                    {strings.instructionLabel} {PhoneFormatterUtil.convertToPhoneNumber(this.phoneNumber)}.
                </div>
                <InputMask mask='999999'
                           value={this.props.store.pinCode}
                           onChange={updatePinCode}>
                    { () => <TextField className={`outlined-basic ${this.state.isValid ? '' : 'invalid' }`}
                                       placeholder={strings.enterPinLabel}
                                       variant='outlined'/>}
                </InputMask>
                <div className='sub-title'>
                    {strings.resendViaLabel}
                </div>
                <div className='buttons'>
                    <Button className='button'
                            onClick={this.props.store.resendPinCodeClicked}>
                        {strings.smsLabel}
                    </Button>
                    <Button className='button'
                            onClick={this.props.store.resendVoiceCodeClicked}>
                        {strings.callLabel}
                    </Button>
                </div>
             </div>
        )
    }
}

export default PINInput;
