
import { ExistingWorkerCheckStatus } from '../models/ExistingWorkerCheckStatus';
import { SecureRegisterAccountModel } from '../models/RegisterDTO';
import {HashingUtil} from "../utils/HashingUtil";
import { SignInDetails} from "../models/SignInDTOs";
import {ConfigService} from "./ConfigService";
import {AuthTokenResponse} from "../models/AuthTokens";
import {AxiosService} from "./axiosService";
import {StorageService} from "./StorageService";

export class SignInService {

    constructor(private httpService: AxiosService) {

    }

    async isExistingWorkerAsync(params: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        requestContext: string
    }): Promise<ExistingWorkerCheckStatus> {
        const {
            phoneNumber,
            firstName,
            lastName,
            requestContext
        } = params;

        if (!phoneNumber) {
            return ExistingWorkerCheckStatus.DOES_NOT_EXIST;
        }

        const api = '/workers/isExistingWorker';
        const response = await this.httpService.get(api,{
            phoneNumber,
            firstName,
            lastName
        });

        const status = response.data?.checkStatus;

        if (!status) {
            throw new Error();
        }

        return status;
    }

    async secureRegisterAccount(request: SecureRegisterAccountModel) {
        const {
            pin,
            transactionId,
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
            password,
            requestContext
        } = request;

        const api = '/secure/registerAccount';

        const response = await this.httpService.post(
            api,
            {
                pin,
                transactionId,
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                password
            }
        );

        const result = response.data;

        if (!result.tokens) {
            throw new Error(result.errorCode);
        }

        return result;
    }

    async secureLogin(loginId: string, password: string): Promise<string> {

        try {
            const hashedData = await HashingUtil.createPassAsync(loginId, password);
            const hashedPassword = hashedData.hashedData;

            const signInDetails: SignInDetails = {
                hashedPassword: hashedPassword,
                loginId,
                password: password
            };

            const response: AuthTokenResponse | string = await this.login(signInDetails);

            if (response instanceof Object && response?.act) {
                this.setLogin(response.act, response.rft);
                return 'true';
            } else {
                return response?.toString();
            }
        } catch (error) {
            return 'false';
        }
    }

    async login(details: SignInDetails): Promise<AuthTokenResponse | string> {

        const api = ConfigService.getInstance().values.constants.AUTH_ENDPOINT;

        const request: SignInDetails = {
            loginId: details.loginId,
            password: details.password,
            hashedPassword: details.hashedPassword
        };

        try {
            const response = await this.httpService.post(api, request);
            return response.data;
        } catch (error) {
            return error.response?.data?.authenticationErrorMessage;
        }
    }

    setLogin(act: string, rft: string) {
        StorageService.setAuthToken(act);
        StorageService.setRefreshToken(rft);
        StorageService.setUserSignedIn(true);
    }
}