export interface RegexValid {
    valid: boolean;
    regexFailedPositions?: number[];
}

export class PasswordValidation {

    private static USER_PASSWORD_VALIDATION_REGEX_RULES = [
        "^[\\S\\s]{8,20}$",
        "(?=.*[A-Z])|(?=.*[$@$!%*?&#^|\\`~)(^])",
        "(?=.*[a-z])",
        "(?=.*\\d)"
    ];

    private static isEmpty(data: string): boolean {
        return !data;
    };

    static isPasswordMatching(password: string, confirmPassword: string): boolean {
        return !this.isEmpty(password) &&
            !this.isEmpty(confirmPassword) &&
            confirmPassword === password;
    };

    private static getPasswordRegexArray(): string[] {
        return PasswordValidation.USER_PASSWORD_VALIDATION_REGEX_RULES;
    };

    private static validatePasswordRule(rule: string, password: string): boolean {
        return password ? !(password.search(new RegExp(rule))) : false;
    };

    static doesMatchAllRequirements(password: string): RegexValid {
        const regexResult: RegexValid = {
            valid: true,
            regexFailedPositions: []
        };
        const regexArray = this.getPasswordRegexArray();

        for (let i = 0; i < regexArray.length; i += 1) {
            if (!this.validatePasswordRule(regexArray[i], password)) {
                regexResult.valid = false;
                regexResult.regexFailedPositions.push(i);
            }
        }
        return regexResult;
    };
}