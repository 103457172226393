import * as React from 'react';
import {Dialog, DialogContent, DialogContentText} from '@material-ui/core';
import StyledModalTitle from '../StyledModalTitle';

interface IProps {
    isOpen: boolean;
    title: string | React.ReactNode;
    content: string | React.ReactNode;
    onClose: () => void;
    className?: string;
}

function InformationalModal(props: IProps) {
    const {
        isOpen,
        title,
        content,
        className,
        onClose
    } = props;

    return (
        <Dialog open={isOpen}
                className={className}
                aria-labelledby="informational-modal-title">
            <StyledModalTitle id="informational-modal-title"
                              onClose={onClose}>
                {title}
            </StyledModalTitle>
            <DialogContent className={className}>
                {
                    typeof content === 'string' ?
                        <DialogContentText>{content}</DialogContentText> :
                        content
                }
            </DialogContent>
        </Dialog>
    );
}

export default InformationalModal;
