import axios, {AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse} from "axios";
import {ConfigService} from "./ConfigService";
import {AxiosInterceptors} from "./axiosInterceptors";

export class AxiosService {

    static serviceInstance: AxiosService = null;
    public axiosInstance: AxiosInstance;

    static getInstance(): AxiosService {
        if (!this.serviceInstance) {
            this.serviceInstance = new AxiosService();
        }

        return this.serviceInstance;
    }
    
    setInterceptors(interceptors: AxiosInterceptors) {
        this.axiosInstance.interceptors.request.use(async function (config) {
            return await interceptors.request(config);
        }, async function (error) {
            return await interceptors.requestError(error);
        });

        this.axiosInstance.interceptors.response.use(async function (response) {
            return await interceptors.response(response);
        }, async function (error) {
            return await interceptors.responseError(error);
        });
    }

    initialise() {
        const configs = ConfigService.getInstance().values;
        const constants = configs.constants;

        const apiUrl = constants.apiUrl;
        const pid = constants.PID;
        const partnerAppId = constants.PARTNER_APP_ID;

        this.axiosInstance = axios.create({
            baseURL: apiUrl,
            timeout: 30000,
            headers: {
                'Request-Origin': 'WORKER',
                reqo: 'WORKER',
                pid: pid,
                partnerAppId: partnerAppId
            }
        });
    }

    async get(api: string, params?: Object, additionalHeaders?: Object): Promise<any> {

        const configs: AxiosRequestConfig = {
            params: params,
            headers: additionalHeaders
        }

        return await this.axiosInstance.get(api, configs);
    }

    async post(api, body: Object, additionalHeaders?: Object): Promise<any> {

        const configs: AxiosRequestConfig = {
            headers: additionalHeaders
        }

        return await this.axiosInstance.post(api, body, configs);
    }
}