import { v4 as uuidv4 } from 'uuid';
import { ScreenRoutes } from '../ActivePage';
import { ClientTrackingNames } from '../models/ClientTracking';
import { ClientTrackingService } from '../services/ClientTrackingService';
import { ReferralService } from '../services/ReferralService';

export class URLParamStore {


    private model = {
        rsCode: '',
        utm_campaign: '',
        programId: '',
        utm_medium: '',
        utm_source: '',
        utm_content: '',
        utm_id: ''
    };

    private clientTracking: ClientTrackingService;

    constructor(private referralService: ReferralService) {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        this.model = {
            rsCode: urlParams.get('rsCode'),
            utm_campaign: urlParams.get('utm_campaign'),
            programId: urlParams.get('programId'),
            utm_medium: urlParams.get('utm_medium'),
            utm_source: urlParams.get('utm_source'),
            utm_content: urlParams.get('utm_content'),
            utm_id: this.getUtmIdFromParams(urlParams)
        };

        this.clientTracking = ClientTrackingService.getInstance();

        if (this.hasMarketingParams) {
            this.clientTracking.trackEvent(
                ClientTrackingNames.MarketingTracking,
                {
                    data: this.model
                });
        }
    }

    getQRDownloadingLink(isDirectAppDownload?: boolean) {
        const pathName = isDirectAppDownload ? ScreenRoutes.AppDownload : ScreenRoutes.AppStore
        const newUrl = new URL(`${window.location.origin}${pathName}${window.location.search}`);

        if (!this.hasMarketingParams) {
            return newUrl.href;
        }

        for (const [key, value] of Object.entries(this.model)) {
            newUrl.searchParams.set(key, value);
        }

        return newUrl.href;
    }

    getUtmIdFromParams(urlParams: URLSearchParams){
        let possibleUtmId : string;
        possibleUtmId  =  urlParams.get('utm_id')
        if(possibleUtmId === null) {
            possibleUtmId = uuidv4();

        }
        return possibleUtmId;
    }

    get hasMarketingParams() {
        return (
            this.model.rsCode &&
            this.model.utm_campaign &&
            this.model.programId &&
            this.model.utm_medium &&
            this.model.utm_source &&
            this.model.utm_content
        )
    }

    private get hasReferralParams() {
        return (this.model.programId && this.model.rsCode);
    }

    confirmReferral() {

        if (!this.hasReferralParams) {
            return;
        }

        this.referralService.createReferral(this.model.rsCode, this.model.programId, this.model.utm_id);
    }

}
