import { configs } from '../config';

export class ConfigService {
    public values: {
        constants: any,
        features: any,
        strings: any
    };
    static serviceInstance: ConfigService = null;

    static getInstance(): ConfigService {
        if (!this.serviceInstance) {
            this.serviceInstance = new ConfigService();
        }

        return this.serviceInstance;
    }

    constructor() {

        const env = process.env.REACT_APP_ENVIRONMENT;
        const environmentSpecificValues = configs[env];

        if (!environmentSpecificValues) {
            console.error(`The Environment ${env} does not exist`);
            return;
        }

        const constants = Object.assign(
            environmentSpecificValues.constants,
            configs.shared.constants
        );

        const features = Object.assign(
            environmentSpecificValues.features,
            configs.shared.features
        );

        const strings = Object.assign(
            environmentSpecificValues.strings,
            configs.shared.strings
        );

        this.values = {
            constants,
            features,
            strings
        }
    }
}
