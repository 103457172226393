import React from 'react';
import {motion} from 'framer-motion';

const SlideInTransition = {
    initial: {
        x: -1000,
    },
    in: {
        x: 0,
    },
    out: {
        x: 1000,
    }
};

const SlideOutTransition = {
    initial: {
        x: 1000,
    },
    in: {
        x: 0,
    },
    out: {
        x: -1000,
    }
};

export interface IProps {
    className?: string;
    in? : boolean;
    out?: boolean;
}

function Slide(props: React.PropsWithChildren<IProps>) {

    return (
        <motion.div className={props.className}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={props.in ? SlideOutTransition : SlideInTransition}
                    transition={{ duration: 0.5}}>
            {props.children}
        </motion.div>
    )
}

export default Slide;