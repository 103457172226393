export const privacyPolicy = `<div class="privacy-policy-content">
    <p><strong>Last updated: March 19, 2021</strong></p>
    <p>
        Summary of changes: We have updated our privacy policy to more clearly show the information we collect, where we get it, and how we use it.</p>
    <h3><strong><span style="color: #2175d9;">Your Privacy Rights</span></strong>&nbsp;</h3>
    <h3><span style="color: #2175d9;">
scope</span></h3>
    <h3></h3>
    <p>This privacy policy applies to all information collected by Randstad North America, Inc. (“Randstad”) and its subsidiaries. Our lines of business include Randstad Accounting &amp; Finance, Randstad General Staffing, Randstad Inhouse Services, Randstad Technologies, Randstad Engineering, Randstad Healthcare, Tatum, and other Randstad brands. The privacy policy describes the personal information we collect, how we use it, and your rights regarding this information. For purposes of this privacy policy, when we use the terms “we,“ “us,“ “our“ or similar terms, we are referring to Randstad.</p>
    <p>Personal information is information that identifies you. This policy applies to personal information we collect online and offline. We collect information online through our websites and mobile apps published and offline through Randstad branch offices.</p>
    <p>Our sites and apps may link to third party sites. Our privacy policy does not cover information collected about you on those sites. Your use of those sites will be governed by the privacy policies and terms of use posted on the sites.</p>
    <h3>
    </h3>
    <h3><span style="color: #2175d9;">overview</span></h3>
    <h3></h3>
    <p>We provide staffing services to clients and job services to candidates seeking current or future job opportunities. We collect personal information to help connect candidates with jobs at Randstad and Randstad clients. We collect this data through our website and apps, over the phone, and in person.</p>
</div>
<div class="col-xs-12">
    <h3><span style="color: #2175d9;">information collection</span></h3>
    <p>We collect information about you when you visit our website, use our services, visit our branch locations, or when you provide it to us in any other way. We also collect information from third party sources.<br>
        <br>
        We collect information when you choose to give it to us. This includes:</p>
    <ul>
        <li id="legal">contact information like your name, phone number, and email address;</li>
        <li id="legal">your work history, including your resume, cover letter, and previous job experience;</li>
        <li id="legal">job application materials including the application form, cover letter, and other material you provide;</li>
        <li id="legal">information you choose to import from third party services like file storage sites or single sign-on services;</li>
        <li id="legal">your precise geographic location;</li>
        <li id="legal">demographic information, including age, ethnicity, and gender. Providing this information is voluntary and is only used by Randstad to meet diversity goals. We do not provide this information to hiring managers.</li>
    </ul>
    <p>
        We collect information about you automatically when you visit our sites. This includes:</p>
    <div>
        <ul>
            <li id="legal">your IP address;</li>
            <li id="legal">your browser and operating system details;</li>
            <li id="legal">actions you take on the site.</li>
        </ul>
        <p>We collect information about you if you visit a branch. This includes:</p>
        <div>
            <ul>
                <li id="legal">
                    your image from a security camera or if you interact with a virtual assistant;</li>
                <li id="legal">any paper materials you provide to us.</li>
            </ul>
        </div>
        <h3><span style="color: #2175d9;">Sources of information</span></h3>
    </div>
    <p>In addition to information we collect from you, we also may collect information about you from third party sources. These sources include:</p>
    <div>
        <ul>
            <li id="legal"><span style="font-size: 13px">third party websites and services like job boards and professional network sites;</span></li>
            <li id="legal"><span style="font-size: 13px">background check providers;</span></li>
            <li id="legal"><span style="font-size: 13px">your references;</span></li>
            <li id="legal"><span style="font-size: 13px">business intelligence sources;</span></li>
            <li id="legal"><span style="font-size: 13px">Randstad clients;</span></li>
            <li id="legal"><span style="font-size: 13px">Randstad affiliates, which are companies that own Randstad, that are owned by Randstad, or are under common ownership with Randstad.</span></li>
        </ul>
        <span style="font-size: 1.17em;"></span></div>
    <h3><span style="color: #2175d9;">information use</span></h3>
</div>
<p class="col-xs-12">
    We use the information we collect for our business purposes, which include:<br>
    <br>
    <strong><span style="color: #2175d9;">Recruiting and hiring</span></strong> – We use contact information and job application data to contact and evaluate candidates for current and future job openings.</p>
<div class="col-xs-12"><strong>
</strong>
    <p><span style="color: #2175d9;">Providing our websites and mobile apps</span></p>
    <ul>
        <li id="legal">We use the information we collect about your device and the information you give us to allow you to create an account on our sites and apps.</li>
        <li id="legal">We use information you provide to us to administer the various services you opt in to, including emails and notifications about career opportunities and other developments.</li>
    </ul>
    <p><strong><span style="color: #2175d9;">Product development and improvement</span></strong> – We use all personal data we collect to develop new products, tools, and services including chatbots and other uses of artificial intelligence.</p>
    <strong>
    </strong>
    <p><span style="color: #2175d9;">Sales and marketing</span></p>
    <ul>
        <li id="legal">We use contact information to make sales calls, and send out advertising materials to candidates, customers, and customer prospects</li>
        <li id="legal">If you create an account on our site, we will send you emails with job content</li>
        <li id="legal">We may use personal data to show you advertisements on our site</li>
    </ul>
    <p><strong>
<span style="color: #2175d9;">
Analytics</span></strong></p>
    <strong>
    </strong>
    <ul>
        <li id="legal">We use the personal data we collect on our site and apps to profile the interests of employers and job seekers, and understand their interests</li>
        <li id="legal">We use information we collect about your device to generate statistics about how you use our site</li>
    </ul>
    <div>&nbsp;</div>
    <p><strong><span style="color: #2175d9;">Security, fraud prevention, and legal compliance</span> </strong>- We use personal data to ensure the security of our sites and services, to protect against fraud, and to comply with legal requirements.</p>
    <p>We may use your information for any other purpose that you choose to opt in to on our sites. We do not sell your data for their direct marketing purposes unless you opt in to such marketing. If you do not want to receive&nbsp;email&nbsp;from us, visit the “Opt Out” or “Unsubscribe” link at the bottom of any Randstad email to remove yourself from continued receipt of such email messages.</p>
    <div>
        <h3><span style="color: #2175d9;">information sharing with third parties</span></h3>
    </div>
    <p>We share personal information with clients, service providers, affiliated companies, and other third parties.<br>
        <br>
        <strong><span style="color: #2175d9;">sharing with clients</span><br>
        </strong><br>
        With your permission, we may submit your resume, application and other information to a prospective employer on the basis that such prospective employer will not 1) disclose the fact that such job seeker is seeking employment and 2) share your personal data with any other person without job your consent or otherwise in compliance with applicable labor and employment laws.<br>
        <br>
        We may share your personal information, with sufficient information removed so that you are not readily identifiable, to prospective employers to solicit their potential interest in you as a job candidate.<br>
        <br>
        <strong><span style="color: #2175d9;">sharing with service providers</span><br>
        </strong><br>
        We share personal information with service providers, which are companies that help us run our business. Service providers include companies that provide marketing services, IT services, HR services, and other business services. These companies will have access to your personal information as necessary to perform their functions, but they may not use that data for any other purpose. We require services providers to abide by the requirements of this privacy policy regarding the use of your personal information.<br>
        <br>
        <strong><span style="color: #2175d9;">sharing with affiliated companies</span><br>
        </strong><br>
        We share personal information with other Randstad companies to help provide our services to you and to improve our own products and services, and to communicate with you.<br>
        <br>
        <strong><span style="color: #2175d9;">sharing with other third parties</span></strong><br>
        <br>
        We may share user information with third parties when we believe, in our sole discretion, that such sharing is appropriate: </p>
    <ul>
        <li id="legal">to comply with the law, including but not limited to, in response to a subpoena served on Randstad;</li>
        <li id="legal">to enforce or apply this privacy policy or other applicable terms, rules or policies;</li>
        <li id="legal">to protect the rights, property or safety of Randstad, our users or others;</li>
        <li id="legal"> or&nbsp;to prevent activity that we believe, in our sole discretion, may be or may become illegal, unethical or legally actionable (including exchanging user information with other companies and organizations for fraud protection).</li>
    </ul>
    <p>We will share personal information with a third party who acquires any or all of Randstad’s business units, whether such acquisition is by way of merger, consolidation or purchase of all or a substantial portion of our assets. In addition, in the event Randstad becomes the subject of an insolvency proceeding, personal information will be disposed of in a transaction approved by the court.</p>
    <p>Any information you post in a public area of the site will be visible to other users and can be read, used, or collected by others. Randstad is not responsible for the way others may use data you post to public areas of the site.</p>
    <p>We may share aggregate personal data from you and others and share it with clients, advertisers, and other third parties. This information does not identify you personally.</p>
    <div>
        <h3><span style="color: #2175d9;">use of cookies</span></h3>
    </div>
    <p>
        We use cookies on our sites and mobile apps to analyze trends, administer websites, track user movements around the website, and to gather demographic information. Cookies help personalize and maximize your use of our site, including storing your preferences, improving your search results and showing you ads. Cookies also help us detect and stop fraud.</p>
    <p>&nbsp;We use the following types of cookies:</p>
    <div>
        <ul>
            <li id="legal">Security: These cookies allow us to secure access to your account.</li>
            <li id="legal">Preference: These cookies are used to store your preferences.</li>
            <li id="legal">Analytics: We track traffic patterns so we can identify popular content and potential problems.</li>
            <li id="legal">Features: We track your activity to show you a customized experience. We also use cookies to test new features.</li>
            <li id="legal">Advertising: Cookies assist us in advertising to you on and off our sites.</li>
        </ul>
    </div>
    <p>Some cookies will remain on your computer after you have left our site. You can control your cookies at the browser level, but if you choose to disable cookies, it may limit your use of certain features on our sites and apps. Some browsers may be configured to send Do Not Track signals to websites. Randstad does not accept or process such Do Not Track signals or similar mechanisms.</p>
    <p>Some of our services use cookies from Google. To opt out of the Google remarketing advertising network, visit&nbsp;<a href="https://adssettings.google.com/authenticated?hl=en">Google's Ad Preference Manager</a>.</p>
    <div><strong style="font-size: 1.17em;"><span style="color: #2175d9;">
</span>
    </strong>
        <h3><span style="color: #2175d9;">information security</span></h3>
    </div>
    <p>Randstad restricts access to the personal information it collects to those who have a valid business need to know that information. Access to personal information is granted based on the minimum access required to fulfill the business need and purpose for which it is collected, used and shared. We endeavor to use commercially reasonable security safeguards,&nbsp;practices&nbsp;and procedures, and we commit to the highest level of care to protect the privacy and integrity of personal information, throughout its useful&nbsp;life-cycle, from collection until destruction. You are responsible for keeping your account credentials safe.&nbsp; </p>
    <h3><span style="color: #2175d9;">information security incident response</span></h3>
    <p>If we discover that your personal information has been involved in a security incident, we will take appropriate steps to respond to the incident in a timely manner and, where legally required, notify you of the incident. </p>
    <h3><span style="color: #2175d9;">retention of personal information</span></h3>
    <p>Randstad will keep your personal information for as long as we have a business need and purpose to retain the information, including storage for auditing and archival purposes. When we believe we no longer need such information, we will destroy or otherwise dispose of your personal information in a manner intended to preserve your privacy and security.</p>
    <h3><span style="color: #2175d9;">accessing and updating your profile</span></h3>
</div>
<p class="col-xs-12">
    You may review, update, or remove your job seeker profile directly on the website. You may also contact us and ask to see your job seeker profile and choices, and you may advise us of any changes or inaccuracies in such profile and choices and we will update or correct as appropriate.</p>
<div class="col-xs-12">
    <h3><span style="color: #2175d9;">consultant texting applicants &amp; employees</span></h3>
    <p>You can opt in to receive text messages from Randstad. These text messages may include, but are not limited to, job postings, assignment updates, weather alerts, and reminders for upcoming events and services.<br>
        <br>
        If at any time you wish to stop receiving text messages, you will have the option to opt out by texting STOP in response to a text from Randstad. You can also contact Randstad and request to have your number opted out by calling&nbsp;Randstad’s Call Center at 877.601.7453.</p>
    <h3><span style="color: #2175d9;">children's privacy</span></h3>
</div>
<p class="col-xs-12">
    Randstad’s services are only available to individuals over the age of 18. Randstad is not intended for and does not knowingly collect personal information from individuals under 18. If we believe you are under 18, we may delete your information at any time without notice to you. If you are the parent or guardian of a child under the age of 13 and believe that your child has provided personal information to us, you may use the “contact us” information below to exercise your rights as a parent or guardian.
</p>
<div class="col-xs-12">
    <h3><span style="color: #2175d9;">changes to this policy</span></h3>
</div>
<p class="col-xs-12">
    We may update this privacy policy to reflect changes in our information practices. If we make any material changes, we will notify you by email (sent to the email address specified in your account) where required, or by a notice on this website. We encourage you to periodically review this page for the latest information on our privacy practices. By engaging with us after this privacy policy has been updated, you agree to the updated terms.<br>
    <br>
</p>
<div class="col-xs-12">
    <h3><span style="color: #2175d9;">reporting fraudulent activities</span>&nbsp;</h3>
</div>
<p class="col-xs-12">If you become aware of any potentially fraudulent activity on our website or in an email or text, please contact Randstad's Call Center at 877.601.7453 so that we can follow up with appropriate actions. </p>
<div class="col-xs-12"><strong><span style="color: #2175d9;">
</span>
</strong>
    <h3><span style="color: #2175d9;">international data transfer</span></h3>
</div>
<p class="col-xs-12">Randstad’s website and services are provided in the United States. If you access the website from outside the United States, you consent that your information may be transferred to and maintained on computers and servers located in the United States. Randstad may further transfer personal information you provide to us to other countries. </p>
<div class="col-xs-12"><strong><span style="color: #2175d9;">
</span>
</strong>
    <h3><span style="color: #2175d9;">rights to request access to or deletion of your personal information</span></h3>
</div>
<p class="col-xs-12">You have the right to know whether Randstad has collected personal information from you. You may request that Randstad tell you:
</p>
<div class="col-xs-12">
    <ul>
        <li id="legal">the categories or specific pieces of personal information that Randstad has collected about you;</li>
        <li id="legal">the categories of sources where Randstad obtained personal information about you;</li>
        <li id="legal">the business purposes for which Randstad collects your personal information; and</li>
        <li id="legal">the categories of third parties with which Randstad shares personal information</li>
    </ul>
    <p>You can make 2 such access requests every 12 months.</p>
    <p>You have the right to request that Randstad delete any of your personal information that Randstad has collected from you, with certain exceptions. We may refuse your deletion request if we are permitted by law to keep the information, or if the law otherwise permits us to keep information. If we deny a deletion request, we will tell you why the request was denied.</p>
    <p>
        To exercise your right to access or delete personal data, complete the form available at this link:<br>
        <a href="https://privacy-request.randstadusa.com/">https://privacy-request.randstadusa.com</a>.<br>
        <br>
        Before we grant you access to or deletion of your personal information, we must verify your identity. To do this, we will ask you to confirm information we have previously collected about you. If we ask you to verify your identity, and you do not promptly cooperate, we may be unable to fulfill your request. We will make commercially reasonable efforts to fulfill your request within 45 days, however if we need additional time we may notify you during the initial 45-day period that we will extend the time frame up to an additional 45 days.
    </p>
    <p>Randstad North America, Inc. is located at One Overton Park, 3625 Cumberland Blvd SE, Atlanta, GA 30339, and is the legal entity determining the purposes and means of processing the personal information collected under this policy.</p>
    <div>
<span style="color: #2175d9;"><strong><span style="font-size: 13px">
</span>
</strong>
</span>
        <h3 style="font-weight: bold;">
        </h3>
        <h3><span style="color: #2175d9;">contact us</span></h3>
    </div>
    <p>If, at any time, you have questions or concerns about this privacy policy, please&nbsp;contact us online at:<br>
        <a href="https://privacy-request.randstadusa.com/">https://privacy-request.randstadusa.com</a>&nbsp;or at:</p>
    <p>
        Privacy Office<br>
        Randstad North America, Inc.<br>
        One Overton Park<br>
        3625 Cumberland Blvd SE<br>
        Atlanta, GA 30339</p>
    <br>
    <p>&nbsp;</p>
</div>`
