export const resources = {
    signUpPage: {
        title: "sign up now to get started"
    },
    pinCodePage: {
        title: 'Register',
        registerButtonLabel: 'Register',
        invalidCodeErrorTitle: 'Invalid PIN code entered.',
        invalidCodeError: 'Please retry or request to receive a new PIN by voice call or SMS.',
        errorModalTitle: 'Error',
        errorModalBody: 'Sorry, an error occurred. Please try again later.'
    },
    downloadPage: {
        downloadCongratulations: "congratulations, your account has been successfully created.",
        scanBelow: 'Scan the QR code below to download the randstad at work app',
        qrDescription: 'We\'re excited to bring  you <em>randstad at work</em>, our new digital platform that gives you around-the-clock access to customized local job opportunities.',
        description: 'Please download the mobile app from the app store link which is matched to your device. Once you have downloaded this please login with the details you just set up to get working.',
        descriptionDeviceDetected: 'please use the link above to download the randstad at work app from the app store. Once you have downloaded this please login with the details you just set up to get working.',
        downloadNowLabel: 'download now',
        redirectLabel1: 'redirecting automatically in {0} seconds. click ',
        redirectLabel2: 'here',
        redirectLabel3: ' to not redirect.',
        featureTitle: 'create your new <em>randstad at work</em> profile to:',
        featureList: [
            {
                title: 'get exclusive early access',
                description: 'We\'ll send you notifications about jobs that match what you\'re looking for as soon as they\'re available. You can pick up jobs 24/7.'
            },
            {
                title: 'put yourself to work',
                description: 'Want to work full-time, part-time, or something in between? With <em>randstad at work</em>, you - and only you - control how much you work.'
            },
            {
                title: 'get full-time benefits - without the full-time grind',
                description: 'With <em>randstad at work</em>, you\'ll be a W-2 employee - and that means you\'ll be entitled to benefits other shift workers only dream of.'
            },
            {
                title: 'we speak your language',
                description: 'iHablamos español! <em>randstad at work</em> is a W2 employee platform that supports both English and Spanish. No translator needed.'
            }
        ]
    },
    immediateDownloadPage: {
        downloadCongratulations: "",
        scanBelow: 'Scan the QR code below to download the randstad at work app',
        appStoreAutoLinking: "You will shortly be directed to the app store, if this fails please click the link below",
        appStoreLinking: 'Please download the mobile app from the app store link which is matched to your device. ',
        qrDescription: 'We\'re excited to bring you <em>randstad at work</em>, our new digital platform that gives you around-the-clock access to customized local job opportunities.',
        description: 'Not using your mobile phone? No problem, register using this device and download the app later.',
        descriptionDeviceDetected: 'Please use the link above to download the randstad at work app from the app store.',
        downloadNowLabel: 'download now',
        redirectLabel1: 'Redirecting automatically in {0} seconds. Click ',
        redirectLabel2: 'here',
        redirectLabel3: ' to not redirect.',
        registerButtonLabel: 'register now',
        featureTitle: 'create your new <em>randstad at work</em> profile to:',
        featureList: [
            {
                title: 'get exclusive early access',
                description: 'We\'ll send you notifications about jobs that match what you\'re looking for as soon as they\'re available. You can pick up jobs 24/7.'
            },
            {
                title: 'put yourself to work',
                description: 'Want to work full-time, part-time, or something in between? With <em>randstad at work</em>, you - and only you - control how much you work.'
            },
            {
                title: 'get full-time benefits - without the full-time grind',
                description: 'With <em>randstad at work</em>, you\'ll be a W-2 employee - and that means you\'ll be entitled to benefits other shift workers only dream of.'
            },
            {
                title: 'we speak your language',
                description: 'iHablamos español! <em>randstad at work</em> is a W2 employee platform that supports both English and Spanish. No translator needed.'
            }
        ]
    },
    header: {
        title: ''
    },
    registration: {
        description: "To apply for and accept these roles, you'll need to create a profile on randstad at work, so create yours today.",
        firstNameLabel: "first name",
        lastNameLabel: "last name",
        emailLabel: "email address",
        cellNumberLabel: "cell number",
        hintTextLabel: "enter text",
        buttonLabel: "register",
        phoneHint: "(000) 000-0000",
        appearsCheckLabel: "Please enter your name as it appears on your ID or Passport",
        validateLabel: "A PIN will be sent to your cell to validate your number",
        disclaimer: "By clicking \"Register\" I agree that Randstad or its representatives may contact me by email, phone or SMS at the email address or number I provide, including for marketing purposes, and that my information will be used in accordance with Randstad\'s",
        legalSmsCheck: "By checking this box, I give my electronic signature authorizing Randstad, its affiliates, subsidiaries, agents, parents, and service providers, to contact me via live agent, prerecorded, or artificial voice call and text (SMS) message, and send me advertising and other calls or messages regarding potential jobs opportunities, mobile application updates, and promotional materials to my phone number that I provided above using an automatic telephone dialing system. I understand that I do not need to sign this agreement, or otherwise agree, as a condition of purchasing any property, goods, or services.",
        errorModalTitle: 'error',
        errorNumberUsedModalTitle: 'this phone number has already been registered.',
        errorMatchModalBody: 'your name does not match the name we have on file for you. Please make sure that you enter your full name, as it presents on your ID.',
        errorModalBody: 'Sorry, an error occurred. Please try again later.',
    },
    createPassword: {
        passwordMessage: 'please choose a password for your account. The password must:',
        passwordPlaceholder: 'password',
        passwordErrorMessage: 'this password does not meet the requirements.',
        confirmPasswordPlaceholder: 'confirm password',
        confirmationPasswordErrorMessage: 'passwords do not match.',
        moreThanEightRule: "have at least 8 characters",
        specialCharacterRule: "include either a capital letter or special character.",
        lowerCaseRule: "include a lower case letter",
        numberRule: "include a number",
        showMessage: 'show password',
        hideMessage: 'hide password'
    },
    pinCode: {
        instructionLabel: 'please enter the 6-digit PIN that was sent to',
        pinLabel: 'pin',
        enterPinLabel: 'enter pin',
        resendViaLabel: 'resend via:',
        smsLabel: 'SMS',
        callLabel: 'voice call'
    },
    landingPage2: {
        content: [
            'randstad at work is the new platform that links you to local job opportunities 24x7.',
            'With the randstad at work app you can connect directly with business in your area. Randstad matches you to jobs based on your skills and preferences. Best of all, you can take a job from the comfort of home.',
            'For exclusive access to jobs as they become available'
        ],
        registerButtonLabel: 'register now',
        findOutMoreLabel: 'find out more'
    },
    landingPage3: {
        content: [
            'randstad at work is the new platform that links you to local job opportunities 24x7.',
            'With the randstad at work app you can connect directly with businesses in your area.\n' +
            'randstad at work matches you to jobs based on your skills and preferences.',
            'Take 1 min to register your interest so that we can tell you about jobs in your area.'
        ],
        registerButtonLabel: 'register now',
        findOutMoreLabel: 'find out more'
    },
    manageShiftTime: {
        title: 'Check in',
        instructions: 'Click Check in below before you start work to mark your start time',
        checkInError: 'There was error checking you into this shift',
        loadError: 'There was an error showing this shift check in, please ensure you are fully onboarded and try again',
        locationError: 'There was an error submitting your entry, Please try again and make sure you have enabled your location',
        cantLocateWorkedShift: "We were unable to find the shift to enter your hours and break times. Please contact your onsite supervisor.",
        qrCodeExpired: "This QR code has expired. Please contact you onsite supervisor to generate a new code.",
        dispatchNotFoundError: "We were unable to find an assigned shift for you at this job site. Please contact your onsite supervisor.",
        startTime: 'Shift Start time:',
        action: 'Action',
        timestamp: 'Timestamp',
        submitted: 'Submitted',
        checkIn: 'Check In',
        checkOut: 'Check Out',
        enterTimeInApp: 'Please confirm your submitted shift times in the hours tab of the app.',
        noIdFoundError: 'Please scan a randstad at work QR code with your device\'s camera to check in or out of your shift'
    },
    loginPage: {
        cellNumber: "Cell Number",
        password: "Password",
        title: "Login",
        submitButton: "Login",
        errorTitle: 'Error'
    },
    landingPage4: {
        content: [
            'randstad at work is the new platform that links you to local job opportunities 24x7.',
            'With the randstad at work app you can connect directly with businesses in your area.\n' +
            'randstad at work matches you to jobs based on your skills and preferences.',
            'Take 1 min to register your interest so that we can tell you about jobs in your area.'
        ],
        registerButtonLabel: 'register now',
        findOutMoreLabel: 'find out more'
    },
    landingPage6: {
        subtitle1: 'great jobs. more flexibility',
        subtitle2: 'right now.',
        intro: 'Introducing <b>randstad at work</b>  — 24/7 access to our new digital platform that puts you in the driver’s seat, allowing you to take control of when you work, where you work and how you lock in your next shift(s).',
        pointsTitle: '<b>randstad at work</b> provides you with:',
        points: [
            'immediate access to jobs and shifts that match what you\'re looking for',
            'the ability to secure opportunities anywhere, anytime',
            'the power to choose when, where and how to work with Randstad'
        ],
        findShifts: 'Find shifts faster — anytime, anywhere — with top companies in your local area.',
        oppotunity: 'We’re excited to be able to offer you this new 24/7 option and connect you with great jobs - right now.',
        download: 'Download the app here and get started right away!',
        direction: '*if you’re an employer looking for workers, please download our client app <a id="download">here</a>.',
        downloadButton: 'Download randstad at work'
    },
    workerPromo: {
        bigTitleSection: {
            primaryTitle: 'find your next role —',
            secondaryTitle: 'anytime, anywhere',
            lastTitle: '— with randstad.'
        },
        beforeLeaderSection: {
            content: ['We\'re excited to bring you randstad at work, the new platform that gives you around-the-clock access to customized local job opportunities.', 'With randstad at work, you can connect directly to local businesses in your area and receive job matches based on your skills and the kinds of roles you\'re looking for.', 'Best of all, you can accept a role right from the comfort of your own home - or wherever you happen to be!']
        },
        leaderSection: {
            title: 'why it pays to sign up for randstad at work',
            registerTitle: 'start applying now!',
            content: ['randstad at work is the new platform that links you to local job opportunities 24x7.',
                'With the randstad at work app you can connect directly with business in your area. randstad at work matches you to jobs based on your skills and preferences. Best of all, you can take a job from the comfort of home.',
                'Register now for access to jobs as they become available.']
        },
        promoSections: [
            {
                title: "get exclusive early access",

                list: [
                    "We'll send you roles that match what you're looking for as soon as they're available, and you can review and apply to them 24/7."
                ]
            },
            {
                title: "take charge of your search",

                list: [
                    "Want to work full-time, part-time or something in between? With randstad at work, you - and only you - control how much you work."
                ]
            },
            {
                title: "get full-time benefits - without the full-time grind",
                list: [
                    "With randstad at work, you'll be a W2 employee - and that means you'll be entitled to benefits other gig workers can only dream of."
                ]
            },
            {
                title: "we speak your language",
                list: [
                    "¡Hablamos español! randstad at work is the only W2 work platform that supports both English and Spanish. No translator needed."
                ]
            },
            {
                title: "",
                list: [
                    "Apply today!"
                ]
            }]
    }

};
