import React from 'react';
import InformationalModal from './Base/InformationalModal';
import {privacyPolicy} from "../../privacyPolicy";
import ReactHtmlParser from 'react-html-parser';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

function PrivacyPolicyModal(props: IProps) {

    return (
        <InformationalModal {...props}
                            className='privacy-policy-modal'
                            title={'Privacy Policy'}
                            content={ReactHtmlParser(privacyPolicy)}/>
    );
}

export default PrivacyPolicyModal;
