import {ConfigService} from "../services/ConfigService";
import bcrypt from 'bcryptjs';

const keyStr: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

export interface IHashPassword {
    hashedData: string;
    error?: any;
}

export class HashingUtil {
    static generateUID() {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
    }

    static async createPassAsync(phoneNumber: string, plainTextPassword: string): Promise<IHashPassword> {

        const configs = ConfigService.getInstance().values;
        const features = configs.features;
        const shouldEncrypt = features.shouldHashPassword;
        if (!shouldEncrypt) {
            let password: IHashPassword = {
                hashedData: plainTextPassword
            };
            return new Promise((resolve) => {
                resolve(password);
            });
        }

        const salt = HashingUtil.genSalt(phoneNumber);
        return await HashingUtil.hashAsync(salt, plainTextPassword);
    }

    static async hashAsync(salt: string, unhashedPassword: string): Promise<IHashPassword> {
        return bcrypt.hash(unhashedPassword, salt).then(resolved => {
            return new Promise((resolve) => {
                resolve({ hashedData: resolved });
            });
        }, rejected => {
            return new Promise((resolve) => {
                resolve({ hashedData: '', error: rejected });
            });
        });
    }

    static genSalt(phoneNumber: string) {
        const id = `${phoneNumber}@swipejobs.com`;
        return `$2a$10$${HashingUtil.base64Encode(id)}`;
    }

    static base64Encode(input: string) {
        let output = '';
        let i = 0;

        input = HashingUtil.utf8Encode(input);

        while (i < input.length) {
            let chrIndex1 = input.charCodeAt(i++);
            let chrIndex2 = input.charCodeAt(i++);
            let chrIndex3 = input.charCodeAt(i++);

            let enc1 = chrIndex1 >> 2;
            let enc2 = ((chrIndex1 & 3) << 4) | (chrIndex2 >> 4);
            let enc3 = ((chrIndex2 & 15) << 2) | (chrIndex3 >> 6);
            let enc4 = chrIndex3 & 63;

            if (isNaN(chrIndex2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chrIndex3)) {
                enc4 = 64;
            }

            output = `${output}${keyStr.charAt(enc1)}${keyStr.charAt(enc2)}${keyStr.charAt(enc3)}${keyStr.charAt(enc4)}`;
        }

        return output;
    }

    private static utf8Encode(str) {
        str = str.replace(/\r\n/g, '\n');
        let utfEncodedText = '';

        for (let chrIndex = 0; chrIndex < str.length; chrIndex++) {

            const charCode = str.charCodeAt(chrIndex);

            if (charCode < 128) {
                utfEncodedText = `${utfEncodedText}${String.fromCharCode(charCode)}`;
            } else if ((charCode > 127) && (charCode < 2048)) {
                utfEncodedText = `${utfEncodedText}${String.fromCharCode((charCode >> 6) | 192)}`;
                utfEncodedText = `${utfEncodedText}${String.fromCharCode((charCode & 63) | 128)}`;
            } else {
                utfEncodedText = `${utfEncodedText}${String.fromCharCode((charCode >> 12) | 224)}`;
                utfEncodedText = `${utfEncodedText}${String.fromCharCode(((charCode >> 6) & 63) | 128)}`;
                utfEncodedText = `${utfEncodedText}${String.fromCharCode((charCode & 63) | 128)}`;
            }
        }

        return utfEncodedText;
    }
}

export default HashingUtil;
