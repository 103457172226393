import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import { includes, isEmpty } from "lodash";
import {AxiosService} from "./axiosService";
import {ProfileService} from "./ProfileService";
import {StorageService} from "./StorageService";
import {PermissionService} from "./PermissionService";

const authenticationRenewEndpoint = 'authentication/renew';
const permissionTokenMissing = 'SJ4050';
const authTokenExpiredException = 'SJ4055';
const sjRenewErrorCode = 'SJ403'; // guest token expired
const sjUnsecuredJwtCode = 'SJ4032';

class RequestStorage {
    private requests = {};

    hasRequest(url: string): boolean {
        return !!this.requests[url];
    }

    addRequest(url: string) {
        this.requests[url] = true;
    }

    cleanRequests() {
        if (isEmpty(this.requests)) {
            return;
        }
        this.requests = {};
    }
}


export class AxiosInterceptors {
    private lastRequests = new RequestStorage();

    constructor(private profileService: ProfileService,
                private permissionService: PermissionService,
                private storageService: StorageService) {

    }

    shouldRequestPermissionToken(url: string) {
        /**
         * We don't need to add the permissions token to itself
         */
        if (includes(url, 'permissions/external/personpermissions/token')) {
            return false;
        }

        return this.profileService.isUserSignedIn();
    }

    async request(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {

        const isRenewingSession = includes(config.url, authenticationRenewEndpoint);
        if (!isRenewingSession) {
            this.lastRequests.cleanRequests();
        }

        const sessionToken = (
            this.profileService.isUserSignedIn() ?
                await this.profileService.getSessionTokenAsync() :
                await this.profileService.getGuestTokenAsync()
        );

        const token = (
            isRenewingSession ?
                await this.profileService.getRefreshToken() :
                sessionToken
        );

        config.headers.Authorization = this.formatBearerToken(token);

        if (this.shouldRequestPermissionToken(config.url)) {

            let permissionToken = StorageService.getPermissionToken();

            if (!permissionToken) {
                try {
                    await this.permissionService.getPermissionToken();
                    permissionToken = StorageService.getPermissionToken();
                } catch (error) {
                    return Promise.reject(error);
                }
            }

            config.headers.permissionToken = permissionToken;
        }

        return config;
    }

    private formatBearerToken(token: string): string {
        return `Bearer ${token}`;
    }

    async requestError(error): Promise<AxiosRequestConfig> {
        return Promise.reject(error);
    }

    async response(response): Promise<AxiosResponse> {
        return response;
    }

    async responseError(error): Promise<AxiosResponse> {

        const shouldAuthRenewToken = (response: Response, data: any) => {
            return response.status === 401 ||
                response.status === 403 && data && data.code === sjRenewErrorCode ||
                response.status === 403 && data && data.code === sjUnsecuredJwtCode;
        };

        const shouldRenewPermissionToken = (response: Response, data: any) => {
            return response.status === 401 ||
                response.status === 403 && data && data.code === authTokenExpiredException ||
                response.status === 403 && data && data.code === permissionTokenMissing;
        };

        const { response } = error;
        if (!response) {
            return Promise.reject(error);
        }

        if (shouldRenewPermissionToken(response, response.data)) {
            try {
                await this.permissionService.getPermissionToken();
                return AxiosService.getInstance().axiosInstance.request(error.config);
            } catch (error) {
                return Promise.reject(error);
            }
        }


        if (shouldAuthRenewToken(response, response.data) &&
            !includes(response.url, authenticationRenewEndpoint)) {
            // this.storageService.setSessionExpired(true);
            console.log('attempting to renew');
            // TODO we should just call renew in the profile service

            // if second time renewing, and there isn't currently a renew token being refreshed for the same endpoint
            if (this.lastRequests.hasRequest(response.url) && this.profileService.hasNotStartedRefreshingAuthToken) {
                console.log('rejecting cause its the second time renewing this url and the renew token not refreshing yet');
                return Promise.reject(error);
            }

            this.lastRequests.addRequest(response.url);

            let didRefresh;
            if (this.profileService.isUserSignedIn()) {
                const accessToken = await StorageService.getAuthToken();
                didRefresh = await this.profileService.getRefreshTokenAsync(accessToken);
            } else {
                didRefresh = await this.profileService.refreshGuestSessionAsync();
                console.log('refreshed guest session');
            }

            if (!didRefresh) {
                return Promise.reject(error);
            }

            return AxiosService.getInstance().axiosInstance.request(error.config);
        }

        return Promise.reject(error);
    }
}

