import {AxiosService} from "./axiosService";
import {StorageService} from "./StorageService";

export class PermissionService {

    constructor(private httpService: AxiosService) {

    }

    async getPermissionToken() {
        const api = 'permissions/external/personpermissions/token'
        const response = await this.httpService.get(api, {});
        console.log(response);

        const token = response.data.tokenString;
        StorageService.setPermissionToken(token);
        return token;
    }
}