export type ClientTrackingActionType = 'click' | 'data' | 'page_view';

export class ClientTrackingActions {
    static readonly Click: ClientTrackingActionType = 'click';
    static readonly Data: ClientTrackingActionType = 'data';
    static readonly Render: ClientTrackingActionType = 'page_view';
}

export type ClientTrackingNameType = 'start' |
    'landing-page' |
    'register_next_click' |
    'register_validation_fail' |
    'pin_confirm_send_code_sms' |
    'pin_confirm_send_code_call' |
    'pin_confirm_next' |
    'pin_confirm_validation_fail' |
    'pin_confirm_invalid_code' |
    'download_app_registration_download_ios' |
    'download_app_registration_download_android' |
    'landing' |
    'register' |
    'pin_confirm'|
    'download_app_registration' |
    'worker_marketing_tracking';

export class ClientTrackingNames {

    //events
    static readonly AppStarted: ClientTrackingNameType = 'start';
    static readonly LandingPage: ClientTrackingNameType = 'landing-page';

    static readonly RegisterNextClick: ClientTrackingNameType = 'register_next_click';
    static readonly RegisterValidationFailed: ClientTrackingNameType = 'register_validation_fail';

    static readonly PinCodeSendCodeSMS: ClientTrackingNameType = 'pin_confirm_send_code_sms';
    static readonly PinCodeSendCodeCall: ClientTrackingNameType = 'pin_confirm_send_code_call';
    static readonly SignInPinConfirm: ClientTrackingNameType = 'pin_confirm_next';
    static readonly SignInPinValidationFail: ClientTrackingNameType = 'pin_confirm_validation_fail';
    static readonly SignInPinInvalidCode: ClientTrackingNameType = 'pin_confirm_invalid_code'; // new event added - for when they enter an incorrect code

    static readonly DownloadIos: ClientTrackingNameType = 'download_app_registration_download_ios';
    static readonly DownloadAndroid: ClientTrackingNameType = 'download_app_registration_download_android';

    static readonly MarketingTracking: ClientTrackingNameType = 'worker_marketing_tracking';

    //page rendering
    static readonly Landing: ClientTrackingNameType = 'landing';
    static readonly Register: ClientTrackingNameType = 'register';
    static readonly PinConfirm: ClientTrackingNameType = 'pin_confirm';
    static readonly DownloadAppRegistration: ClientTrackingNameType = 'download_app_registration';

}


