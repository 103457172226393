import * as React from 'react';
import LandingPage1 from './pages/landingPages/landingPage1';
import LandingPage2 from './pages/landingPages/landingPage2';
import LandingPage3 from './pages/landingPages/landingPage3';
import LandingPage4 from './pages/landingPages/landingPage4';
import LandingPage5 from './pages/landingPages/landingPage5';
import LandingPage6 from './pages/landingPages/landingPage6';

import PinConfirmation from "./pages/PinConfirmation";
import DownloadNow from "./pages/DownloadNow"
import ImmediateDownloadNow from "./pages/ImmediateDownloadNow";
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom';
import { WorkerRegistrationStore } from './stores/WorkerRegistrationStore';
import history from './services/history';
import { AnimatePresence } from 'framer-motion';
import RegistrationFormLandingPage from "./pages/landingPages/RegistrationFormLandingPage";
import {ConfigService} from "./services/ConfigService";
import Login from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ManageShiftTime from "./pages/ManageShiftTime";
import {useEffect} from "react";
import RedirectDeveloperStore from './pages/RedirectDeveloperStore';


interface IProps {
    store: WorkerRegistrationStore;
}

export enum ScreenRoutes {
    LandingPage1 = '/landing-1',
    LandingPage2 = '/landing-2',
    LandingPage3 = '/landing-3',
    LandingPage4 = '/landing-4',
    LandingPage5 = '/landing-5',
    LandingPage6 = '/landing-6',
    LandingPage7 = '/landing-7',
    Talroo1 = '/talroo-1',
    Talroo2 = '/talroo-2',
    Promo = 'promo',
    Download = '/download',
    PinConfirmation = '/pin-confirmation',
    SignUp = '/sign-up',
    Login = '/login',
    ManageShiftTime = '/manage-shift-times',
    AppDownload = '/app-download',
    AppStore = '/app-store'
}

const AuthorisedScreens = [
    ScreenRoutes.ManageShiftTime
]

const UnAuthorisedScreens = [
    ScreenRoutes.PinConfirmation,
    ScreenRoutes.SignUp,
    ScreenRoutes.Login,
    ScreenRoutes.AppDownload,
]

function ActivePage(props: IProps) {
    const configs = ConfigService.getInstance().values;
    const constants = configs.constants;

    const getDefaultLandingPage = {
        ['LandingPage1']: () => <LandingPage1 workerRegistrationStore={props.store}/>,
        ['LandingPage2']: () => <LandingPage2 workerRegistrationStore={props.store}/>,
        ['LandingPage3']: () => <LandingPage3 workerRegistrationStore={props.store}/>,
        ['LandingPage4']: () => <LandingPage4 workerRegistrationStore={props.store}/>,
        ['LandingPage5']: () => <LandingPage5 workerRegistrationStore={props.store}
                                        registrationStore={props.store.registrationStore}/>,
        ['LandingPage6']: () => <LandingPage6 workerRegistrationStore={props.store}/>,
    }

    const onRouteChange = (route, params) =>  {

        if (AuthorisedScreens.includes(route) && !props.store.isUserAuthenticated) {
            history.replace({
                pathname: ScreenRoutes.Login,
                search: params
            });
        }
    }

    useEffect(() => {
        onRouteChange(window.location.pathname, window.location.search);
        history.listen(onRouteChange.bind(this));
    },[])

    return(
        <div className="page-container">
            <AnimatePresence>
                <Router history={history}>
                    <Route render={({location}) => (
                        <Switch location={location}
                                key={location.pathname}>
                            <Route path={ScreenRoutes.LandingPage1}>
                                <RegistrationFormLandingPage registrationStore={props.store.registrationStore}
                                                             workerRegistrationStore={props.store}/>
                            </Route>
                            <Route path={ScreenRoutes.LandingPage2}>
                                <LandingPage2 workerRegistrationStore={props.store}/>
                            </Route>
                            <Route path={ScreenRoutes.LandingPage3}>
                                <LandingPage3 workerRegistrationStore={props.store}/>
                            </Route>
                            <Route path={ScreenRoutes.Talroo1}>
                                <LandingPage3 workerRegistrationStore={props.store} talroo={true}/>
                            </Route>
                            <Route path={ScreenRoutes.Talroo2}>
                                <RegistrationFormLandingPage registrationStore={props.store.registrationStore}
                                                             workerRegistrationStore={props.store}
                                                             talroo={true}/>
                            </Route>

                            <Route path={ScreenRoutes.LandingPage4}>
                                <LandingPage4 workerRegistrationStore={props.store}/>
                            </Route>
                            <Route path={ScreenRoutes.Promo}>
                                <LandingPage4 workerRegistrationStore={props.store}/>
                            </Route>
                            <Route path={ScreenRoutes.LandingPage5}>
                                <LandingPage5 registrationStore={props.store.registrationStore}
                                                             workerRegistrationStore={props.store}/>
                            </Route>
                            <Route path={ScreenRoutes.LandingPage6}>
                                <LandingPage6 workerRegistrationStore={props.store}/>
                            </Route>
                            <Route path={ScreenRoutes.LandingPage7}>
                                <LandingPage6 workerRegistrationStore={props.store} withAppImages/>
                            </Route>
                            {
                                props.store.isUserAuthenticated &&
                                <Route path={ScreenRoutes.Download}>
                                    <DownloadNow workerRegistrationStore={props.store}/>
                                </Route>
                            }
                            {
                                props.store.hasTransactionId &&
                                <Route path={ScreenRoutes.PinConfirmation}>
                                    <PinConfirmation store={props.store.pinCodeStore}
                                                     workerRegistrationStore={props.store}/>
                                </Route>
                            }
                            <Route path={ScreenRoutes.SignUp}>
                                <SignupPage registrationStore={props.store.registrationStore}
                                            workerRegistrationStore={props.store}/>
                            </Route>

                            <Route path={ScreenRoutes.Login}>
                                <Login workerRegistrationStore={props.store}/>
                            </Route>

                            <Route path={ScreenRoutes.ManageShiftTime}>
                                <ManageShiftTime workerRegistrationStore={props.store}/>
                            </Route>

                            <Route path={ScreenRoutes.AppDownload}>
                                <ImmediateDownloadNow workerRegistrationStore={props.store}/>
                            </Route>

                            <Route path={ScreenRoutes.AppStore}>
                                <RedirectDeveloperStore workerRegistrationStore={props.store}/>
                            </Route>

                            <Route path="/">
                                {getDefaultLandingPage[constants.DEFAULT_LANDING_PAGE]}
                            </Route>
                        </Switch>
                    )}
                    />
                </Router>
            </AnimatePresence>
        </div>
    )
}

export default ActivePage;
