import {
    createStyles,
    Theme
} from '@material-ui/core';

const styles = (theme: Theme) => (
    createStyles({
        root: {
            margin: 0,
            paddingBottom: theme.spacing(1)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[800]
        },
        bottomLine: {
            height: 2,
            position: 'absolute',
            left: 0,
            right: 0,
            backgroundColor: 'grey',
            display: 'block',
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3)
        }
    })
);

export default styles;
