import React from 'react';
import { resources } from '../../resources/strings';
import PageTitle from '../../components/PageTitle';
import { WorkerRegistrationStore } from '../../stores/WorkerRegistrationStore';
import Slide from '../../AnimationTransitions/SlideInTransition';
import TextField from "@material-ui/core/TextField/TextField";
import {TextInput} from "../../components/create-password-container/index-styled-compontents";
import InputMask from 'react-input-mask';
import {Button} from "@material-ui/core";
import {observer} from "mobx-react";
import {LoginStore} from "../../stores/LoginStore";

import './styles.scss';
import {LoaderOverlay} from "@swipejobs/frontend-react-core-web-components";
import history from "../../services/history";
import InformationalModal from "../../components/Modals/Base/InformationalModal";

interface IProps {
    workerRegistrationStore: WorkerRegistrationStore;
}

@observer
class Login extends React.Component<IProps>{

    private store: LoginStore;

    constructor(props) {
        super(props);
        this.store = props.workerRegistrationStore.loginStore;
    }

    componentDidMount() {
        this.props.workerRegistrationStore.showHeader();
        this.store.logOutUser();
    }

    render() {

        const strings = resources.loginPage;

        return(
            <Slide in className='standard login-page'>
                <LoaderOverlay isLoading={this.store.isSigningIn} />
                <PageTitle text={strings.title}/>
                <div className={'login-wrapper'}>
                    <div className={'login-container'}>
                        <span className='sub-title'>{strings.cellNumber}</span>
                        <InputMask mask='(999) 999 9999'
                                   value={this.store.cellNumber}
                                   onChange={ (event) => this.store.setCellNumber(event.target?.value)}>
                            { () => <TextField className='outlined-basic'
                                               placeholder={strings.cellNumber}
                                               variant='outlined'/>}
                        </InputMask>
                        <span className='sub-title'>{strings.password}</span>
                        <TextField
                            className='outlined-basic'
                            type={'password'}
                            placeholder={strings.password}
                            variant={'outlined'}
                            onChange={(event) => this.store.setPassword(event.target?.value)}
                        />
                    </div>
                    <div className={'footer'}>
                        <Button className='button'
                                disabled={!this.store.isValid}
                                onClick={() => this.store.submitClicked()}>
                            {strings.submitButton}
                        </Button>
                    </div>
                </div>
                <InformationalModal isOpen={!!this.store.loginErrorMessage}
                                    onClose={() => this.store.loginErrorMessage = ''}
                                    title={strings.errorTitle}
                                    content={<div> {this.store.loginErrorMessage}</div>} />
            </Slide>
        )

    }

}

export default Login;
