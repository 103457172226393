import * as React from 'react';
import './styles.scss';

interface IProps {
    text: string;
}

function PageTitle(props: IProps) {

    return (
        <div className="page-title">
            {props.text}
        </div>
    );
}

export default PageTitle;