import { Button } from '@material-ui/core';
import parse from 'html-react-parser';
import React from 'react';
import './landingPage.scss';
import './landingPage6.scss';
import { ScreenRoutes } from '../../ActivePage';

import { ClientTrackingNames } from '../../models/ClientTracking';
import { resources } from '../../resources/strings';
import { ConfigService } from '../../services/ConfigService';
import { WorkerRegistrationStore } from "../../stores/WorkerRegistrationStore";

interface IProps {
    workerRegistrationStore: WorkerRegistrationStore;
    talroo?: boolean;
    withAppImages?: boolean;
}

class LandingPage6 extends React.Component<IProps> {
    private strings = resources.landingPage6;
    private link: string;
    private oppositeLink: string = null;

    constructor(props) {
        super(props);

        this.link = `${ScreenRoutes.AppDownload}${window.location.search}`;
        this.oppositeLink = `${ConfigService.getInstance().values.constants?.CUSTOMER_APP_LINK}${window.location.search}`;
    }

    componentWillMount() {
        this.props.workerRegistrationStore.clientTracking.trackRender(ClientTrackingNames.Landing, { page: 6 });
        this.props.workerRegistrationStore.hideHeader();

        if(this.props.talroo) {
            this.props.workerRegistrationStore.showTalrooPixel();
        }
    }

    linkToPartnerSite() {
        const websiteLink = ConfigService.getInstance().values.constants?.WEBSITE_LINK;
        websiteLink && (window.location.href = websiteLink);
    }

    render() {

        const headerDecor = '/images/header-image-decor.png';

        return (
            <div className='landing-page-6 landing-page-full'>
                <div className={'header-panel'}>
                    <img src={headerDecor}
                         className={'decor-logo'}
                         alt={'decor'}>
                    </img>
                    <div onClick={() => this.linkToPartnerSite()}>
                        <img src={'/images/header-image-top.png'}
                             className={'header-logo'}
                             alt={'header'}>
                        </img>
                    </div>
                </div>
                <div className='content-box'>
                    <div>
                        <div className={'sub-title'}>{this.strings.subtitle1}</div>
                        <div className={'sub-title-secondary'}>{this.strings.subtitle2}</div>
                    </div>
                    <div>
                        <p>{parse(this.strings.intro)}</p>
                        <p>{parse(this.strings.pointsTitle)}</p>
                        <ul>{this.strings.points.map(point => <li>{point}</li>)}</ul>
                        <p className={'italics'}>{this.strings.findShifts}</p>
                        <p>{this.strings.oppotunity}</p>
                        <p className={'centered space'}>{parse(this.strings.download, {
                            replace: ({ attribs }) => attribs && attribs.id === 'download' && <a href={this.link}>here</a>
                        })}</p>
                        {
                            this.props.withAppImages &&
                            <div className={'app-images'}>
                                <img className={'screenshot'} src={'/images/screen1.png'}/>
                                <img className={'screenshot'} src={'/images/screen2.png'}/>
                                <img className={'screenshot'} src={'/images/screen3.png'}/>
                            </div>
                        }

                        <div className={'button-container'}>
                            <Button className='button secondary'
                                    onClick={() => window.location.href = this.link}>
                                {this.strings.downloadButton}
                            </Button>
                        </div>
                        {
                            this.oppositeLink ? (
                                <p  className={'centered'}>{parse(this.strings.direction, {
                                    replace: ({ attribs }) => attribs && attribs.id === 'download' && <a href={this.oppositeLink}>here</a>
                                })}</p>
                            ) : <p  className={'centered'}>{this.strings.direction}</p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPage6;
