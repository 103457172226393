import {computed, observable} from "mobx";
import {SignInService} from "../services/SignInService";
import TokenService from "../services/TokenService";
import {stripPhoneNumberFormatting} from "../utils/StripPhoneNumberFormatting";
import history from "../services/history";
import {StorageService} from "../services/StorageService";
import {ScreenRoutes} from "../ActivePage";

export class LoginStore {

    @observable cellNumber: string = '';
    @observable password: string = '';

    @observable isSigningIn: boolean = false;
    @observable loginErrorMessage = '';

    constructor(private signInService: SignInService) {

    }

    setCellNumber(cellNumber: string) {
        this.cellNumber = cellNumber;
    }

    setPassword(password: string) {
        this.password = password;
    }

    @computed
    get isValid() {
        return stripPhoneNumberFormatting(this.cellNumber).length === 10 && this.password?.length > 0
    }

    logOutUser() {
        StorageService.setUserSignedIn(false);
        StorageService.removeAuthToken();
    }

    navigateToManageShift() {
        history.push({
            pathname: ScreenRoutes.ManageShiftTime,
            search: window.location.search
        })
    }

    async submitClicked(): Promise<boolean> {

        this.isSigningIn = true;
        try {
            const cleanCellNumber = stripPhoneNumberFormatting(this.cellNumber);
            const result = await this.signInService.secureLogin(cleanCellNumber, this.password);

            if(result === 'true') {
                this.navigateToManageShift();
            } else {
                this.loginErrorMessage = result;
            }
            this.isSigningIn = false;
        } catch (error) {
            this.isSigningIn = false;
            return false;
        }
    }
}