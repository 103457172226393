//xxx copied from ionic frontend - worker/develop branch

import {
    PinCodeModel,
    pinCodeNotificationTypeMap, PinCodeRequest,
    PinCodeRequestType,
    PinCodeServiceType,
    PinCodeServiceTypes,
    PinRequestReferrerPage, SendCodeResponse
} from '../models/PinCodeModels';
import { RequestContext } from '../models/RequestContext';
import { stripPhoneNumberFormatting } from '../utils/StripPhoneNumberFormatting';
import { HttpService, IApiConfigurationService } from '@swipejobs/frontend-comms-services';
import _ from 'lodash';
import TokenService from './TokenService';
import {AxiosService} from "./axiosService";

export class PinCodeService {

    constructor(private httpService: AxiosService) {
    }

    async trySendCodeAsync(model: PinCodeModel, options: {
        pinCodeServiceType: PinCodeServiceType;
        referrerPage?: PinRequestReferrerPage;
        requestContext?: RequestContext;
        customerConsent?: boolean;
        requestType?: PinCodeRequestType;
        errorCodesMessages?: {
            [key: string]: {
                title: string;
                message: string;
            };
        };
    }): Promise<SendCodeResponse> {
        const {
            pinCodeServiceType,
            referrerPage,
            requestContext,
            customerConsent,
            requestType
        } = options;


        try {
            const phoneNumber = model.phoneNumber && stripPhoneNumberFormatting(model.phoneNumber);
            const params = {
                phoneNumber,
                emailAddress: model.email,
                customerConsent:_.isBoolean(customerConsent) ? customerConsent :
                                _.isBoolean(model.legalCheckbox) ? model.legalCheckbox :
                                _.isBoolean(model.smsConsent) ? model.smsConsent :
                                _.isBoolean(model.customerConsent) ? model.customerConsent :
                                true,
                currentPage: referrerPage,
                requestContext,
                preferredNotificationType: pinCodeNotificationTypeMap[pinCodeServiceType],
                requestType
            };

            model.externalId && Object.assign(params, {
                clientId: model.externalId
            });

            const response = await this.sendCodeRequestAsync(
                pinCodeServiceType,
                params
            );

            if (!response.transactionId) {
                return {
                    destination: null,
                    errorCode: response.errorCode
                }
            }

            model.transactionId = response.transactionId;
            model.phoneNumber = phoneNumber;

            return {
                destination: response.userAddress
            };

        } catch(error) {
            //todo sentry
            return {
                destination: null,
                errorCode: 'unknown'
            };
        }
    }

    private sendCodeRequestAsync(service: PinCodeServiceType, request: PinCodeRequest) {
        const pinCodeTypeMap = {
            [PinCodeServiceTypes.Sms]: () => this.smsRequestAsync(request),
            [PinCodeServiceTypes.Call]: () => this.voiceCallRequestAsync(request)
        };
        return pinCodeTypeMap[service]();
    }

    private async smsRequestAsync(request: PinCodeRequest) {
        const {
            phoneNumber,
            customerConsent,
            currentPage,
            requestContext
        } = request;

        const api = '/sessions/pinCodeRequest'
        const response = await this.httpService.post(api,{
            phoneNumber,
            customerConsent,
            currentPage
        });

        return response.data;
    }

    private async voiceCallRequestAsync(request: PinCodeRequest) {
        const {
            phoneNumber,
            customerConsent,
            currentPage,
            requestContext      //todo need to include request context for BE
        } = request;

        const api = '/sessions/voicePinCodeRequest'
        const response = await this.httpService.post(api,{
            phoneNumber,
            customerConsent,
            currentPage
        });

        return response.data;
    }
}
