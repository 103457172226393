export class PhoneFormatterUtil {

    static convertToPhoneNumber = (phone: string) => {

        if (!phone) {
            return '';
        }

        const value = phone.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return phone;
        }

        let country = '';
        let city = '';
        let numberString = '';
        let extension = '';

        if (value.length < 10) {
            return phone;
        }

        if (value.length === 10) {
            // +1PPP####### -> C (PPP) ###-####
            country = '1';
            city = value.slice(0, 3);
            numberString = value.slice(3);
        } else if (value.length === 11) { // +CPPP####### -> CCC (PP) ###-####
            country = value[0];
            city = value.slice(1, 4);
            numberString = value.slice(4);
        } else if (value.length === 12) {// +CCCPP####### -> CCC (PP) ###-####
            country = value.slice(0, 3);
            city = value.slice(3, 5);
            numberString = value.slice(5);
        } else if (value.length > 12) {
            country = '1';
            city = value.slice(0, 3);
            numberString = value.slice(3, 10);
            extension = 'EXT: ' + value.slice(10);
        }

        if (country === '1') {
            country = '';
        }

        if (numberString === undefined) {
            return phone;
        }

        numberString = `${numberString.slice(0, 3)}-${numberString.slice(3, 10)}`;

        return `${country} (${city}) ${numberString} ${extension}`.trim();
    }
}
