import './styles.scss';
import { WorkerRegistrationStore } from '../../stores/WorkerRegistrationStore';
import { observer } from 'mobx-react';
import React from 'react';
import history from '../../services/history';

interface IProps {
    store: WorkerRegistrationStore;
}

@observer
class Header extends React.Component<IProps> {

    constructor(props) {
        super(props);
    }

    render() {

        if (!this.props.store._showHeader) {
            return null;
        }

        const goBack = () => history.goBack();

        const logo = '/images/header-image-top.png';

        return (
            <div className='sj-header'>
                {
                    this.props.store._showBackBtn &&
                    <div className='back'
                         onClick={goBack}/>
                }
                <img src={logo}
                     className={'logo'}
                     alt={'swipejobs'}>
                </img>
            </div>
        );

    };
}

export default Header;
