import { JobTitle } from '../models/JobTitle';
import _ from 'lodash';
import { AxiosService } from './axiosService';

const jobTitlesMap: {
    [jobTitleId: string]: JobTitle;
} = {};

export class JobTitleService {

    async getJobTitlesByIdsAsync(
        ids: string[]
    ) {
        let collectedJobTitles = [];

        const uniqJobTitleIds = _.uniq(ids);
        const neededJobTitleIds = _.reduce(uniqJobTitleIds,
            (result, id) => {
                const jobTitle = jobTitlesMap[id];
                if (jobTitle) {
                    collectedJobTitles.push(jobTitle);
                } else {
                    result.push(id);
                }

                return result;
            },
            []
        );

        if (!neededJobTitleIds[0]) {
            return collectedJobTitles;
        }

        const api = '/jobtitle/jobtitles/jobTitlesByIds';
        const body = {
            jobTitleIds: neededJobTitleIds
        };

        const response = await  AxiosService.getInstance().post(api, body);
        const data = response.data;

        const returnedJobTitles = <JobTitle[]>data;
        _.forEach(returnedJobTitles, jobTitle => {
            jobTitlesMap[jobTitle.id] = jobTitle;
            collectedJobTitles.push(jobTitle);
        });

        return collectedJobTitles;
    }


}
