import React from 'react';
import { Button } from '@material-ui/core';
import {WorkerRegistrationStore} from "../../stores/WorkerRegistrationStore";
import { Link } from 'react-router-dom';
import './landingPage3.scss';
import { ClientTrackingNames } from '../../models/ClientTracking';
import {ConfigService} from "../../services/ConfigService";
import {resources} from "../../resources/strings";

interface IProps {
    workerRegistrationStore: WorkerRegistrationStore;
    talroo?: boolean;
}

function LandingPage3(props: IProps){
    props.workerRegistrationStore.hideHeader();

    if(props.talroo) {
        props.workerRegistrationStore.showTalrooPixel();

    }

    React.useEffect(() =>
            props.workerRegistrationStore.clientTracking.trackRender(
                ClientTrackingNames.Landing,
                {page: 3}),
        []
    );

    const configs = ConfigService.getInstance().values;
    const constants = configs.constants;
    const findMoreLink = constants.WEBSITE_LINK;

    const strings = resources.landingPage3;

    return(
        <div className="landing-page-3">
            <div className='content-container'>
                <div className='logo-container' />
                <div className='text-container'>
                    {
                        strings.content.map((item: string, index: number) => (<p key={index}>{item}</p>))
                    }
                </div>
                <div className='action-container'>
                    <Link className='register-button'
                          to={'/sign-up'}>
                        <Button className='button register-button'>{strings.registerButtonLabel}</Button>
                    </Link>
                    <a className='find-out-more' href={findMoreLink}>
                        {strings.findOutMoreLabel}
                    </a>
                </div>
            </div>
            <div className={'overlay'}/>
        </div>
    )
}

export default LandingPage3;
