import parse from 'html-react-parser';
import React from 'react';
import {isIOS, isAndroid, isBrowser} from 'react-device-detect';
import { resources } from '../../resources/strings';
import './styles.scss';
import PageTitle from '../../components/PageTitle';
import { WorkerRegistrationStore } from "../../stores/WorkerRegistrationStore";
import { ClientTrackingNames } from '../../models/ClientTracking';
import Slide from '../../AnimationTransitions/SlideInTransition';
import { observer } from 'mobx-react';
import { format } from '../../utils/formatStrings';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {ConfigService} from "../../services/ConfigService";
import QRCode from "qrcode.react";

interface IProps {
    workerRegistrationStore: WorkerRegistrationStore;
}

@observer
class ImmediateDownloadNow extends React.Component<IProps> {
    private strings = resources.immediateDownloadPage;
    private store = this.props.workerRegistrationStore.appDownloadStore;

    componentDidMount() {
        this.props.workerRegistrationStore.showHeader();
        this.props.workerRegistrationStore.hideBackBtn();

        this.store.clientTracking.trackRender(ClientTrackingNames.DownloadAppRegistration);
        this.store.startRedirectTimerAt(0, true);
    }

    private getIOS = () => {

        const logo = '/images/apple-badge.png';

        return (
            <img src={logo}
                 onClick={() => this.store.iosBannerClicked()}
                 className={'logo'}
                 alt={'ios download'}>
            </img>
        )
    };

    private getAndroid = () => {

        const logo = '/images/google-badge.png';

        return (
            <img src={logo}
                 onClick={() => this.store.androidBannerClicked()}
                 className={'logo'}
                 alt={'android download'}>
            </img>
        )
    };

    private getRedirectMessage() {
        const redirectLabel1 = format(this.strings.redirectLabel1, [this.store.redirectTimer]);
        const redirectLink = <span className='stop-redirect'
              onClick={this.store.stopRedirectTimer}>
            {this.strings.redirectLabel2}
        </span>

        return (
            <div className='redirect'>
                {redirectLabel1}
                {redirectLink}
                {this.strings.redirectLabel3}
            </div>
        );
    }


    get featureList() {

        const configs = ConfigService.getInstance().values;
        const features = configs.features;

        if(!features.showDownloadFeatures) {
            return null;
        }

        return (
            <div className={'featureContainer'}>
                <span className={'featureTitle'}>{parse(this.strings.featureTitle)}</span>
                {
                    this.strings.featureList.map(feature => {
                        return (
                            <div className={'featureItem'}>
                                <div className={'featureSubTitle'}>{feature.title}</div>
                                <div className={'featureDescription'}>{parse(feature.description)}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    private getAppsContainer = () => {
        const iosBanner = this.getIOS();
        const androidBanner = this.getAndroid();

        if (isBrowser) {

            return (
                <div>
                    <span className={'congratulationsText'}>{this.strings.scanBelow}</span>
                    <div className='apps-container'>
                        {this.QRCodeContainer}
                    </div>
                    <div>{parse(this.strings.qrDescription)}</div>
                    {this.featureList}
                </div>
            )
        }

        if (!isIOS && !isAndroid) {
            return (
                <div>
                    <span className={'congratulationsText'}>{this.strings.appStoreLinking}</span>
                    <div className='apps-container'>
                        {iosBanner}
                        {androidBanner}
                    </div>
                    <p>{this.strings.description}</p>
                    <Link className='register-button'
                          to={'/sign-up'}>
                        <Button className='button register-button'>{this.strings.registerButtonLabel}</Button>
                    </Link>
                </div>
            )
        } else {
            return (
                <div>
                    <span className={'congratulationsText'}>{this.strings.appStoreAutoLinking}</span>
                    <div className='apps-container'>
                        {isIOS && iosBanner}
                        {isAndroid && androidBanner}
                    </div>
                    <span className={'successText'}>{this.strings.descriptionDeviceDetected}</span>
                    { this.store.canRedirect && (isIOS || isAndroid) && this.getRedirectMessage() }
                </div>
            )
        }
    };

    private getTrackingPixel = () => {
        //consider passing in a value here to allow different pixels to be displayed.
        if(!this.props.workerRegistrationStore._talrooPixelDisplay){
            return null;
        }
        return(
            <img src="https://www.jobs2careers.com/conversion2.php?p=6185" width="1" height="1" />
        );
    };

    get QRCodeContainer() {

        if (!isBrowser) {
            return null;
        }
        const paramStore = this.props.workerRegistrationStore.urlParamStore;

        return <QRCode value={paramStore.getQRDownloadingLink(true)}
                       size={200}/>
    }

    render() {
        const appsContainer = this.getAppsContainer();

        const trackingPixel = this.getTrackingPixel();

        return(
            <Slide in className='standard download-now'>
                <PageTitle text={this.strings.downloadNowLabel}/>

                <div className='apps-container-wrapper'>
                    {appsContainer}
                    {trackingPixel}
                </div>
            </Slide>
        )
    }

}

export default ImmediateDownloadNow;
