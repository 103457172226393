import * as React from 'react';
import PrivacyPolicyModal from '../Modals/PrivacyPolicyModal';
import ParsedLabel, { ReplacementMap } from '../Labels/ParsedLabel';

function Disclaimer(props: {
    text: string;
    className: string;
    openPrivacyPolicy: () => void;
}) {
    const replacements: ReplacementMap = {
        'privacy-policy-link': {
            type: 'tag',
            name: 'a',
            replacement: ({ children }) => {
                const linkChildren = children?.map((childNode) => childNode?.data ?? '') ?? null;
                return (
                    <a href="#"
                       onClick={props.openPrivacyPolicy}
                       style={{
                           textDecoration: 'underline',
                           cursor: 'pointer'
                       }}>
                        {linkChildren}
                    </a>
                );
            }
        }
    };

    return (
        <div>
            <span>{`${props.text} `}</span>
            <ParsedLabel htmlText={`<a id='privacy-policy-link'>privacy policy</a>`}
                         className={props.className}
                         replacements={replacements} />
        </div>

    );
}

interface IProps {
    disclaimerText: string;
}

function PrivacyPolicyDisclaimer(props: IProps) {
    const [isPrivacyPolicyShowing, setPrivacyPolicyShowing] = React.useState(false);
    return (
        <React.Fragment>
            <Disclaimer text={props.disclaimerText}
                        className='disclaimer'
                        openPrivacyPolicy={() => setPrivacyPolicyShowing(true)} />
            <PrivacyPolicyModal isOpen={isPrivacyPolicyShowing}
                                onClose={() => setPrivacyPolicyShowing(false)} />
        </React.Fragment>
    );
}

export default PrivacyPolicyDisclaimer;
