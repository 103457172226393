import "reflect-metadata";
import * as React from 'react';
import ActivePage from "./ActivePage";
import './App.scss';

import {
  Switch,
  Route,
  useHistory
} from 'react-router-dom';
import { WorkerRegistrationStore } from './stores/WorkerRegistrationStore';
import { ConfigProvider } from '@swipejobs/frontend-config-provider';
import {
    HttpService,
    registerInterceptors,
    ApiConfigurationService
} from '@swipejobs/frontend-comms-services';
import TokenService from './services/TokenService';
import Header from './components/Header';
import { configs } from './config';
import {AxiosService} from "./services/axiosService";
import {AxiosInterceptors} from "./services/axiosInterceptors";
import {ProfileService} from "./services/ProfileService";
import {StorageService} from "./services/StorageService";
import {PermissionService} from "./services/PermissionService";




function App() {

    /******* Initial setup for now - do not touch or it will stop working *********/


    const httpService = AxiosService.getInstance();
    httpService.initialise();

    const storageService = new StorageService();
    const permissionService = new PermissionService(httpService);
    const profileService = new ProfileService(storageService);

    const axiosInterceptors = new AxiosInterceptors(profileService, permissionService ,storageService);

    httpService.setInterceptors(axiosInterceptors);

    const store = new WorkerRegistrationStore(httpService, profileService);

    /******* END *********/

    return (
        <div className='App'>
            <Header store={store}/>
            <ActivePage store={store}/>
        </div>
    );
}

export default App;
