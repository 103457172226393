
enum StorageKey {
    loggedIn = 'isLoggedIn'
}

export class StorageService {

    static isUserSignedIn() {
        const stringValue = localStorage.getItem(StorageKey.loggedIn);
        return stringValue === 'true';
    }

    static setUserSignedIn(value: boolean) {
        const valueString = value ? 'true' : 'false';
        localStorage.setItem(StorageKey.loggedIn, valueString);
    }

    static getAuthToken() {
        return localStorage.getItem('act');
    }

    static setAuthToken(token: string) {
        return localStorage.setItem('act', token);
    }

    static removeAuthToken() {
        localStorage.removeItem('act');
    }

    getGuestToken() {
        const gstToken = localStorage.getItem('gst');

        if (!gstToken){
            return null;
        }

        return gstToken;
    }

    setGuestToken(token: string) {
        localStorage.setItem('gst', token);
    }

    static getRefreshToken(): string {
        return localStorage.getItem('rft');
    }

    static setRefreshToken(token: string) {
        localStorage.setItem('rft', token);
    }

    static setPermissionToken(token) {
        localStorage.setItem('pmt', token);
    }

    static getPermissionToken() {
        return localStorage.getItem('pmt');
    }

}