import { action, computed, observable } from 'mobx';
import { LandingPageStore } from './LandingPageStore';
import { PinCodeStore } from './PinCodeStore';
import { AppDownloadStore } from './AppDownloadStore';
import { RegistrationStore } from './RegistrationStore';
import {
    ApiConfigurationService,
    HttpService,
    IApiConfigurationService,
    IHttpService
} from '@swipejobs/frontend-comms-services';
import { SignInService } from '../services/SignInService';
import { PinCodeService } from '../services/PinCodeService';
import { ClientTrackingService } from '../services/ClientTrackingService';
import TokenService from '../services/TokenService';
import history from '../services/history';
import { PinCodeModel } from '../models/PinCodeModels';
import { ClientTrackingNames } from '../models/ClientTracking';
import { URLParamStore } from './URLParamStore';
import { ReferralService } from '../services/ReferralService';
import {ConfigService} from "../services/ConfigService";
import {LoginStore} from "./LoginStore";
import {AxiosService} from "../services/axiosService";
import {ProfileService} from "../services/ProfileService";
import {ScreenRoutes} from "../ActivePage";

export class WorkerRegistrationStore {

    @observable public _showHeader: boolean = false;
    @observable public _showBackBtn: boolean = false;
    @observable public _talrooPixelDisplay: boolean = false;

    // stores
    public registrationStore: RegistrationStore;
    public pinCodeStore: PinCodeStore;
    public appDownloadStore: AppDownloadStore;
    public urlParamStore: URLParamStore;
    public loginStore: LoginStore;

    //services
    private signInService: SignInService;
    private pinCodeService: PinCodeService;
    private referralService: ReferralService;

    public clientTracking: ClientTrackingService;

    //model
    private pinCodeModel: PinCodeModel = {};

    constructor(httpService: AxiosService,
                private profileService: ProfileService) {

        this.clientTracking = ClientTrackingService.getInstance();
        this.clientTracking.trackEvent(ClientTrackingNames.AppStarted);

        this.signInService = new SignInService(httpService);
        this.pinCodeService = new PinCodeService(httpService);
        this.referralService = new ReferralService(httpService);

        this.urlParamStore = new URLParamStore(this.referralService);

        this.registrationStore = new RegistrationStore(
            this.pinCodeService,
            this.signInService,
            this.navigateToPinConfirm,
            this.pinCodeModel
        );

        this.pinCodeStore = new PinCodeStore(
            this.pinCodeService,
            this.signInService,
            this.urlParamStore ,
            this.navigateToDownload,
            this.pinCodeModel
        );

        this.loginStore = new LoginStore(
            this.signInService
        )

        this.appDownloadStore = new AppDownloadStore();

        this.clientTracking.trackEvent(ClientTrackingNames.LandingPage, {landing: window.location.pathname});
    }

    @action.bound
    public showTalrooPixel() {
        const configs = ConfigService.getInstance().values;
        const features = configs.features;
        this._talrooPixelDisplay = features.talrooEnabled;
        return this._talrooPixelDisplay;
    }

    @action.bound
    public showHeader() {

        if (!this._showHeader) {
            this._showHeader = true;
        }
    }

    @action.bound
    public hideHeader() {

        if (this._showHeader) {
            this._showHeader = false;
        }
    }

    @action.bound
    public showBackBtn() {
        if (!this._showBackBtn) {
            this._showBackBtn = true;
        }
    }


    @action.bound
    public hideBackBtn() {
        if (this._showBackBtn) {
            this._showBackBtn = false;
        }
    }

    @computed
    get hasTransactionId() {
        return this.pinCodeModel?.transactionId
    }

    @computed
    get isUserAuthenticated() {
        return !!this.profileService.isUserSignedIn()
    }

    // if you go back and forward ot will send a new pin code - so may as well wipe the current one
    navigateToPinConfirm = () =>  {
        this.pinCodeStore.wipePinCode();
        history.push(ScreenRoutes.PinConfirmation);
    };

    navigateToDownload = () => {
        history.push(ScreenRoutes.Download);
    }

}
