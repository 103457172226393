export const configs = {
    shared: {
        constants: {
            APPLICATION_NAME: "Worker Landing",
            APP_TYPE: "uapp",
            PARTNER_ID: "sj",
            PIWIK_URL: "https://piwik.swipejobs.com/piwik/",
            PIWIK_CATEGORY: "worker_landing",
            APP_STORE_IOS_LINK: 'https://apps.apple.com/us/app/randstad-talent/id1580658663',
            APP_STORE_ANDROID_LINK: 'https://play.google.com/store/apps/details?id=exchange.ondemand.randstad.worker',
            APP_STORE_IOS_DEVELOPER_LINK: 'https://apps.apple.com/us/developer/swipejobs/id1410616312',
            APP_STORE_ANDROID_DEVELOPER_LINK: 'https://play.google.com/store/apps/developer?id=randstad+at+work&hl=en_AU&gl=US',
            WEBSITE_LINK: "https://www.randstadusa.com",
            APP_ICON_URL: "https://imgs.swipejobs.com/randstad/favicon.svg",
            DEFAULT_LANDING_PAGE: "LandingPage5",
            AUTH_ENDPOINT: "secure/authenticate",
            PID: "RS",
            PARTNER_APP_ID: "rs" 
        },
        features: {
            talrooEnabled: false,
            shouldHashPassword: false,
            showDownloadFeatures: true
        },
        strings: {}
    },
    "dev": {
        "constants": {
            "AUTH_INTERCEPTOR": "sj-exchange",
            "HOST_URL": "https://rsus-api-dev.ondemand.exchange/v3/",
            "API_VERSION_URL": "https://s3.amazonaws.com/version-number/randstad-dev-uapp-config.json",
            "BUNDLE_VERSION": "1.0.0",
            "ENVIRONMENT": "dev",
            "apiUrl": "https://api.rsus.dev.sj-exchange.com/v3/",
            piwikTracking: {
                mobile: 76,
                desktop: 76
            },
            CUSTOMER_APP_LINK: "https://randstad-dev-customer-landing.swipejobs.com/app-download"
        },
        "features": {},
        "strings": {

        }
    },
    "preprod": {
        "constants": {
            "AUTH_INTERCEPTOR": "sj-exchange",
            "HOST_URL": "https://rsus-api-preprod.ondemand.exchange/v3/",
            "API_VERSION_URL": "https://s3.amazonaws.com/version-number/randstad-preprod-uapp-config.json",
            "BUNDLE_VERSION": "1.0.0",
            "ENVIRONMENT": "preprod",
            "apiUrl": "https://api.rsus.preprod.sj-exchange.com/v3/",
            piwikTracking: {
                mobile: 77,
                desktop: 77
            },
            CUSTOMER_APP_LINK: "https://randstad-preprod-customer-landing.swipejobs.com/app-download"
        },
        "features": {},
        "strings": {

        }
    },
    "prod": {
        "constants": {
            "AUTH_INTERCEPTOR": "sj-exchange",
            "HOST_URL": "https://rsus-api-prod.ondemand.exchange/v3/",
            "API_VERSION_URL": "https://s3.amazonaws.com/version-number/randstad-prod-uapp-config.json",
            "BUNDLE_VERSION": "1.0.0",
            "ENVIRONMENT": "prod",
            "apiUrl": "https://api.rsus.prod.sj-exchange.com/v3/",
            piwikTracking: {
                mobile: 78,
                desktop: 78
            },
            CUSTOMER_APP_LINK: "https://customer-landing.rs.on-demandexchange.com/app-download"
        },
        "features": {},
        "strings": {

        }
    }
};
