import {StorageService} from "./StorageService";
import {AxiosService} from "./axiosService";
import history from "./history";
import {ScreenRoutes} from "../ActivePage";

export class ProfileService {
    private refreshTokenPromise: Promise<boolean> = null;

    constructor(private storageService: StorageService) {
    }

    isUserSignedIn() {
        return StorageService.isUserSignedIn();
    }

    get hasNotStartedRefreshingAuthToken(): boolean {
        return !this.refreshTokenPromise;
    }

    private async requestGuestTokenAsync(): Promise<string> {
        try {
            const response = await AxiosService.getInstance().get('sessions/guest');
            return response.data?.token;
        } catch (error) {
            return null;
        }
    }

    async initialiseGuestSessionAsync(): Promise<boolean> {
        const guestToken = await this.getGuestTokenAsync();
        if (guestToken) {
            this.storageService.setGuestToken(guestToken);
            return true;
        } else {
            return await this.refreshGuestSessionAsync();
        }
    }

    async refreshGuestSessionAsync(): Promise<boolean> {
        const guestToken = await this.requestGuestTokenAsync();

        if (guestToken) {
            this.storageService.setGuestToken(guestToken);
            return true;
        } else {
            return false;
        }
    }

    async getGuestTokenAsync(): Promise<string> {
        return this.storageService.getGuestToken();
    }

    getRefreshToken(): string {
        return StorageService.getRefreshToken();
    }

    async getSessionTokenAsync(): Promise<string> {
        return StorageService.getAuthToken();
    }

    async getRefreshTokenAsync(accessToken: string): Promise<boolean> {

        const startRefresh = !this.refreshTokenPromise;

        if (startRefresh) {
            this.refreshTokenPromise = this.doRefreshAsync(accessToken);
        }

        const didRefresh = await this.refreshTokenPromise;

        if (startRefresh) {
            this.refreshTokenPromise = null; // set it back to null when finished
        }

        return didRefresh;
    }

    async doRefreshAsync(oldToken: string): Promise<boolean> {

        const refreshToken = await this.getRefreshToken();
        if (!refreshToken) {
            return false;
        }
        const body = {
            type: 'act',
            token: oldToken
        };

        try {
            const response = await AxiosService.getInstance().post('authentication/renew', body);
            if (response) {
                StorageService.setAuthToken(response.data.token);
                return true;
            }
        } catch (error) {
            await this.doLogoutAsync();
        }
    }

    async doLogoutAsync(): Promise<void> {
        StorageService.setUserSignedIn(false);
        StorageService.removeAuthToken();
        history.push(ScreenRoutes.Login);
    }
}