import React from 'react';
import Register from '../../components/Register/';
import { RegistrationStore } from '../../stores/RegistrationStore';
import { resources } from '../../resources/strings';
import PageTitle from '../../components/PageTitle';
import { WorkerRegistrationStore } from '../../stores/WorkerRegistrationStore';
import Slide from '../../AnimationTransitions/SlideInTransition';

interface IProps {
    registrationStore: RegistrationStore;
    workerRegistrationStore: WorkerRegistrationStore;

}

function SignupPage(props: IProps){

    const signUpStrings = resources.signUpPage;
    props.workerRegistrationStore.showHeader();

    return(
        <Slide in className='standard sign-up-page'>
            <PageTitle text={signUpStrings.title}/>
            <Register store={props.registrationStore}
                      workerRegistrationStore={props.workerRegistrationStore} />
        </Slide>
    )
}

export default SignupPage;
