import { action, computed, observable } from 'mobx';
import { ClientTrackingService } from '../services/ClientTrackingService';
import { ExistingWorkerCheckStatus } from '../models/ExistingWorkerCheckStatus';
import { ClientTrackingActions, ClientTrackingNames } from '../models/ClientTracking';
import { RequestContext } from '../models/RequestContext';
import { PinCodeModel, PinCodeServiceTypes, PinRequestReferrerPages } from '../models/PinCodeModels';
import { PinCodeService } from '../services/PinCodeService';
import { SignInService } from '../services/SignInService';
import { stripPhoneNumberFormatting } from '../utils/StripPhoneNumberFormatting';
import { resources } from '../resources/strings';

export class RegistrationStore {

    @observable public firstName: string = '';
    @observable public lastName: string = '';
    @observable public email: string = '';
    @observable public cellNumber: string = '';

    @observable public _transactionId: string;
    @observable isLoading: boolean = false;

    @observable public isModalOpen: boolean = false;
    @observable public modalTitle: string = '';
    @observable public modalBody: string = '';

    showDetailsNotMatchModal: boolean = false;

    public clientTracking: ClientTrackingService;

    constructor(private $pinCodeService: PinCodeService,
                private $signInService: SignInService,
                private successNavigation: () => void,
                private pinCodeModel: PinCodeModel) {

        this.clientTracking = ClientTrackingService.getInstance();
    }


    @action.bound
    updateFirstname(text: string) {
        //todo  on change validation checks
        this.firstName = text;
    }

    @action.bound
    updateLastName(text: string) {
        this.lastName = text;
    }

    @action.bound
    updateEmail(text: string) {
        this.email = text;
    }

    @action.bound
    updateCellNumber(text: string) {
        this.cellNumber = text;
    }

    @action.bound
    async submitClicked() {
        this.sendInitialPin();
    }

    @action.bound
    async closeModal() {
        this.isModalOpen = false;
    }

    @computed
    get transactionId() {

        if (!this._transactionId) {
            console.error('transaction Id has not been set yet');
            return null;
        }

        return this._transactionId;
    }

    @action.bound
    async sendInitialPin() {

        try {
            this.clientTracking.trackClick(ClientTrackingNames.RegisterNextClick, {cell: this.cellNumber});
            this.isLoading = true;

            const status = await this.$signInService.isExistingWorkerAsync(
                {
                    phoneNumber: stripPhoneNumberFormatting(this.cellNumber),
                    firstName: this.firstName,
                    lastName: this.lastName,
                    requestContext: RequestContext.Registration
                }
            );
            this.isLoading = false;

            if (status === ExistingWorkerCheckStatus.EXISTS_DETAILS_NOT_MATCH) {
                this.showDetailsNotMatchModal = true;
                const strings = resources.registration;
                this.openModal(strings.errorModalTitle, strings.errorMatchModalBody);
                return;
            }

        } catch (error) {
            console.log('error', error);
            this.isLoading = false;
            //todo do we want sentry
            this.showNetworkErrorModal();
            throw error;
        }

        try {

            this.pinCodeModel.phoneNumber = this.cellNumber;
            this.pinCodeModel.firstName = this.firstName;
            this.pinCodeModel.lastName = this.lastName;
            this.pinCodeModel.email = this.email;

            this.isLoading = true;

            const response = await this.$pinCodeService.trySendCodeAsync(
                this.pinCodeModel,
                {
                    pinCodeServiceType: PinCodeServiceTypes.Sms,
                    referrerPage: PinRequestReferrerPages.InitialRegistrationPin,
                    requestContext: RequestContext.Registration
                }
            );
            this.isLoading = false;
            const errorCode = response.errorCode;
            if (errorCode) {
                if (errorCode === 'ERR1004') {
                    const strings = resources.registration;
                    this.openModal(strings.errorModalTitle, strings.errorNumberUsedModalTitle);
                    return;
                }
                this.showNetworkErrorModal();
                return;
            }

            this.successNavigation();
        } catch (error) {
            console.error(error);
            this.showNetworkErrorModal();
            this.isLoading = false;
            this.clientTracking.trackClick(ClientTrackingNames.RegisterValidationFailed, {cell: this.cellNumber});
        }
    }

    private showNetworkErrorModal() {
        const strings = resources.registration;
        this.openModal(strings.errorModalTitle, strings.errorModalBody);
    }

    private openModal(title: string, body: string) {
        this.isModalOpen = true;
        this.modalTitle = title;
        this.modalBody = body;
    }
}
