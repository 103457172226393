import React from 'react';
import './landingPage.scss';
/*import './landingPage2.scss';*/
import './landingPage5.scss';
import { ClientTrackingNames } from '../../models/ClientTracking';
import Header from '../../components/Header';
import { resources } from '../../resources/strings';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import LensIcon from '@material-ui/icons/Lens';
import Register from "../../components/Register";
import {WorkerRegistrationStore} from "../../stores/WorkerRegistrationStore";
import {RegistrationStore} from "../../stores/RegistrationStore";
import LandingHeader from '../../components/LandingHeader';

interface IProps {
    registrationStore : RegistrationStore;
    workerRegistrationStore: WorkerRegistrationStore;
    talroo?: boolean;
}

class LandingPage5 extends React.Component<IProps> {
    private strings = resources.workerPromo;

    componentWillMount() {
        this.props.workerRegistrationStore.clientTracking.trackRender(ClientTrackingNames.Landing, { page: 4 });
        this.props.workerRegistrationStore.hideHeader();

        if(this.props.talroo) {
            this.props.workerRegistrationStore.showTalrooPixel();
        }
    }


    get largeTitles() {

        const titles  =this.strings.bigTitleSection;

        return (
            <div className='big-title'>
                <div className={'no-wrap-title'}>{titles.primaryTitle}</div>
                <div className={'no-wrap-title'}>{titles.secondaryTitle}</div>
                <div className={'no-wrap-title'}>{titles.lastTitle}</div>
            </div>
        )
    }

    render() {
        return (
            <div className='landing-page-5 landing-page-full'>
                <div className='landing-page-header'>
                    <LandingHeader isCentred={true}/>
                </div>
                <div className='landing-page-content'>
                    <div className='content-left'>
                        <div className='section'>
                            {this.largeTitles}
                        </div>
                        <div className='section'>
                            <div className='body'>
                                {
                                    this.strings.beforeLeaderSection.content.map((item: string, index: number) => (<p key={index}>{item}</p>))
                                }
                            </div>
                        </div>
                        <div className='section'>
                            <div className='title'>{this.strings.leaderSection.title}</div>
                        </div>
                        <div className='section'>
                            <div className='body'>
                                {
                                    this.strings.promoSections.map((item, index) => (
                                        <div key={index}>
                                            <div className='item-title'>{item.title}</div>
                                            <div className='item-body'>
                                                {
                                                    item.list.map((item: string, index: number) => (<div key={index}>{item}</div>))
                                                }
                                            </div>

                                        </div>)
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='content-right'>
                        <div className='title'>{this.strings.leaderSection.registerTitle}</div>
                        <Register store={this.props.registrationStore} workerRegistrationStore={this.props.workerRegistrationStore}/>
                    </div>
                </div>
                <a className='down-button'
                   href='#register'>
                    <div className='foreground'>
                        <ArrowDropDownCircleIcon />
                    </div>
                    <div className='background'>
                        <LensIcon />
                    </div>
                </a>
            </div>
        );
    }
}

export default LandingPage5;
