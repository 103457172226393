import { action, observable } from 'mobx';
import { PasswordStore } from './PasswordStore';
import { SignInService } from '../services/SignInService';
import { PinCodeService } from '../services/PinCodeService';
import { PinCodeModel, PinCodeServiceType, PinCodeServiceTypes, PinRequestReferrerPages } from '../models/PinCodeModels';
import { ClientTrackingNames } from '../models/ClientTracking';
import { ClientTrackingService } from '../services/ClientTrackingService';
import { resources } from '../resources/strings';
import TokenService from '../services/TokenService';
import { URLParamStore } from './URLParamStore';
import {StorageService} from "../services/StorageService";
import {RequestContext} from "../models/RequestContext";

export class PinCodeStore {

    public passwordStore : PasswordStore;
    @observable public pinCode: string;
    @observable isLoading: boolean = false;
    @observable incorrectCodeError: boolean = false;

    @observable public isModalOpen: boolean = false;
    @observable public modalTitle: string = '';
    @observable public modalBody: string = '';


    public clientTracking: ClientTrackingService;

    constructor(private $pinCodeService: PinCodeService,
                private $signInService: SignInService,
                private urlParamStore: URLParamStore,
                private navigateToDownload: () => void,
                private pinCodeModel: PinCodeModel) {
        this.passwordStore = new PasswordStore();

        this.clientTracking = ClientTrackingService.getInstance();
    }

    @action.bound
    wipePinCode() {
        this.pinCode = '';
    }

    @action.bound
    async resendPinCodeClicked() {
        await this.resendPin(PinCodeServiceTypes.Sms);
    }

    @action.bound
    async resendVoiceCodeClicked() {
        await this.resendPin(PinCodeServiceTypes.Call);
    }

    @action.bound
    async closeModal() {
        this.isModalOpen = false;
    }

    private async resendPin(type: PinCodeServiceType) {
        this.isLoading = true;


        const response = await this.$pinCodeService.trySendCodeAsync(
            this.pinCodeModel,
            {
                pinCodeServiceType: type,
                referrerPage: PinRequestReferrerPages.InitialRegistrationPin,
                requestContext: RequestContext.Registration
            });

        if (!response.destination) {
            this.showErrorModal();
        }

        const event = type === PinCodeServiceTypes.Sms ?
            ClientTrackingNames.PinCodeSendCodeSMS :
            ClientTrackingNames.PinCodeSendCodeCall;

        this.clientTracking.trackEvent(event, {cell: this.pinCodeModel.phoneNumber});

        this.isLoading = false;

    }

    @action.bound // user has clicked confirm
    async confirmPin() {

        this.clientTracking.trackClick(ClientTrackingNames.SignInPinConfirm, {cell: this.pinCodeModel.phoneNumber});

        this.passwordStore.submitPasswords();
        const canGoNext = this.passwordStore.arePasswordsCorrect;

        if (canGoNext) {
          //  this.registrationStore.setPassword(this.passwordStore.getPassword);
            await this.registerUser();
        }
    }

    @action.bound
    async registerUser() {
        this.isLoading = true;
        this.incorrectCodeError = false;

        try {
            const result = await this.$signInService.secureRegisterAccount({
                pin: this.pinCode,
                transactionId: this.pinCodeModel.transactionId,
                firstName: this.pinCodeModel.firstName,
                lastName: this.pinCodeModel.lastName,
                emailAddress: this.pinCodeModel.email,
                phoneNumber:this.pinCodeModel.phoneNumber,
                password: this.passwordStore.getPassword,
                requestContext: null            //todo request Context
            });
            this.isLoading = false;

            StorageService.setAuthToken(result.tokens.act);
            StorageService.setRefreshToken(result.tokens.rft);
            StorageService.setUserSignedIn(true);

            this.clientTracking.setLoginId(this.pinCodeModel.phoneNumber);
            this.urlParamStore.confirmReferral();
            this.navigateToDownload();
        } catch (error) {
            console.error(error);
            this.isLoading = false;

            this.clientTracking.trackEvent(ClientTrackingNames.SignInPinValidationFail, {cell: this.pinCodeModel.phoneNumber});

            if (error.message === 'ERR1005') {
                this.clientTracking.trackEvent(ClientTrackingNames.SignInPinInvalidCode, {cell: this.pinCodeModel.phoneNumber});
                this.incorrectCodeError = true;
            } else {
                this.showErrorModal(true);
            }
        }
    }

    private showErrorModal(pinCodeError?: boolean) {
        const strings = resources.pinCodePage;
        this.isModalOpen = true;
        this.modalTitle = pinCodeError ? strings.invalidCodeErrorTitle : strings.errorModalTitle;
        this.modalBody = pinCodeError ? strings.invalidCodeError : strings.errorModalBody;
    }
}
