import React from 'react';
import './landingPage.scss';
import './landingPage2.scss';
import './RegistrationFormLandingPage.scss';
import { ClientTrackingNames } from '../../models/ClientTracking';
import { resources } from '../../resources/strings';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import LensIcon from '@material-ui/icons/Lens';
import Register from "../../components/Register";
import {WorkerRegistrationStore} from "../../stores/WorkerRegistrationStore";
import {RegistrationStore} from "../../stores/RegistrationStore";
import LandingHeader from '../../components/LandingHeader';

interface IProps {
    registrationStore : RegistrationStore;
    workerRegistrationStore: WorkerRegistrationStore;
    talroo?: boolean;
}

class RegistrationFormLandingPage extends React.Component<IProps> {
    private strings = resources.workerPromo;

    componentWillMount() {
        this.props.workerRegistrationStore.clientTracking.trackRender(ClientTrackingNames.Landing, { page: 4 });
        this.props.workerRegistrationStore.hideHeader();

        if(this.props.talroo) {
            this.props.workerRegistrationStore.showTalrooPixel();
        }
    }



    render() {
        return (
            <div className='landing-page landing-page-full landing-page-with-image'>
                <div className='overlay' />
                <div className='landing-page-header'>
                    <LandingHeader
                        isCentred
                        isLarge
                    />
                </div>
                <div className='landing-page-content'>
                    <div className='content-left'>
                        <div className='section'>
                            <div className='title'>{this.strings.leaderSection.title}</div>
                            <div className='body'>
                                {
                                    this.strings.leaderSection.content.map((item: string, index: number) => (<p key={index}>{item}</p>))
                                }
                            </div>
                        </div>
                        <div className='section'>
                            <div className='body'>
                                {
                                    this.strings.promoSections.map((item, index) => (
                                        <div key={index}>
                                            <div className='item-title'>{item.title}</div>
                                            <div className='item-body'>
                                                {
                                                    item.list.map((item: string, index: number) => (<p key={index}>{item}</p>))
                                                }
                                            </div>

                                        </div>)
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='content-right'>
                        <div className='title'>{this.strings.leaderSection.registerTitle}</div>
                        <Register store={this.props.registrationStore} workerRegistrationStore={this.props.workerRegistrationStore}/>
                    </div>
                </div>
                <a className='down-button'
                   href='#register'>
                    <div className='foreground'>
                        <ArrowDropDownCircleIcon />
                    </div>
                    <div className='background'>
                        <LensIcon />
                    </div>
                </a>
            </div>
        );
    }
}

export default RegistrationFormLandingPage;
