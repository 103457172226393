import {observable, action, computed} from 'mobx';
import { PasswordValidation, RegexValid } from '../utils/PasswordValidation';

export class PasswordStore {
    @observable wasPasswordSubmitted: boolean = false;
    @observable private password: string= '';
    @observable private confirmPassword: string = '';
    @observable isPasswordValid: RegexValid = initialRegexValid;
    @observable passwordsMatch: boolean = false;
    @observable showPassword: boolean = false;
    @observable showConfirmationPassword: boolean = false;

    @computed
    get arePasswordsEmpty (): boolean {
        return !this.password || !this.confirmPassword ;
    };

    @computed
    get isButtonDisable (): boolean {
        return this.wasPasswordSubmitted ?
            (!this.isPasswordValid.valid || !this.passwordsMatch) : this.arePasswordsEmpty;
    };

    @computed
    get arePasswordsCorrect (): boolean {
        return this.wasPasswordSubmitted && this.isPasswordValid.valid && this.passwordsMatch;
    };

    @action.bound
    submitPasswords = (): void => {
        this.wasPasswordSubmitted = true;
    };

    @computed
    get getPassword (): string {
        return this.password;
    };
    @action.bound
    setPassword = (password: string): void => {
        this.password = password;
    };
    @action.bound
    setConfirmPassword = (confirmPassword: string): void => {
        this.confirmPassword = confirmPassword;
    };

    @action.bound
    validatePassword = (): void  => {
        this.isPasswordValid = PasswordValidation.doesMatchAllRequirements(this.password);
    };
    @action.bound
    validateConfirmPassword = (): void  => {
        this.passwordsMatch = PasswordValidation.isPasswordMatching(this.password, this.confirmPassword);
    };

    @action.bound
    changePasswordVisibility = (): void => {
        this.showPassword = !this.showPassword;
    };

    @action.bound
    changeConfirmationPasswordVisibility = (): void => {
        this.showConfirmationPassword = !this.showConfirmationPassword;
    };
}

export const initialRegexValid = {
    valid: false,
    regexFailedPositions:[]
};