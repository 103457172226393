import {AxiosService} from "./axiosService";
import {StorageService} from "./StorageService";
import {TimeEntryRequest} from "../models/TimeEntryRequest";
import {
    QRRecordsResponse,
    QRShiftInfo
} from '../models/QRRecords';

export class TimeAttendanceService {

    public static instance: TimeAttendanceService = null;

    private httpService: AxiosService;

    constructor() {
        this.httpService = AxiosService.getInstance();
    }

    static getInstance() {
        if (this.instance === null) {

            this.instance = new TimeAttendanceService();
        }
        return this.instance;
    }

    async fetchQRInformation(qrCodeId: string) {

        const api = `time-attendance/external/qrcode/${qrCodeId}`
        const response = await this.httpService.get(api, {});
        return response.data;
    }

    async fetchQRWorkerRecords(qrCodeId: string): Promise<QRRecordsResponse> {
        const api = `time-attendance/external/personqrcodeentry/${qrCodeId}`;
        const response = await this.httpService.get(api, {});
        return response.data;
    }

    async fetchShiftInformation(qrCodeId: string): Promise<QRShiftInfo> {
        const api = `time-attendance/external/workerdispatch/shiftDetail/${qrCodeId}`;
        const response = await this.httpService.get(api, {});
        return response.data;
    }

    async createPersonalEntry(entry: TimeEntryRequest) {
        const api = 'time-attendance/external/personqrcodeentry';
        const response = await this.httpService.post(api, entry)
        return response.data;
    }

}