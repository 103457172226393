import React from 'react';
import PINInput from "../../components/PINInput";
import SetPassword from "../../components/SetPassword";
import CreatePasswordContainer from '../../components/create-password-container';
import { PasswordStore } from '../../stores/PasswordStore';
import { PinCodeStore } from '../../stores/PinCodeStore';
import PageTitle from '../../components/PageTitle';
import { resources } from '../../resources/strings';
import { Button } from '@material-ui/core';
import { LoaderOverlay } from '@swipejobs/frontend-react-core-web-components';
import { observer } from 'mobx-react';
import './styles.scss';
import { WorkerRegistrationStore } from '../../stores/WorkerRegistrationStore';
import { ClientTrackingNames } from '../../models/ClientTracking';
import InformationalModal from '../../components/Modals/Base/InformationalModal';
import Slide from '../../AnimationTransitions/SlideInTransition';

interface IProps {
    store: PinCodeStore;
    workerRegistrationStore: WorkerRegistrationStore;
}

interface IState {
    submitClicked: boolean;
}

@observer
class PinConfirmation extends React.Component<IProps, IState> {
    private strings = resources.pinCodePage;

    constructor(props: IProps) {
        super(props);
        props.workerRegistrationStore.showHeader();

        this.state = {
            submitClicked: false
        }
    }

    componentDidMount() {
        this.props.store.clientTracking.trackRender(ClientTrackingNames.PinConfirm);
    }

    render() {

        const getError = () => {

            if (!this.props.store.incorrectCodeError) {
                return null;
            }

            return <div className='error-message'>{this.strings.invalidCodeError}</div>
        };

        const invalidCode = getError();
        this.props.workerRegistrationStore.showBackBtn();

        const submit = () => this.setState({ submitClicked: true }, () => this.props.store.confirmPin());

        return (
            <Slide in className='standard pin-confirm-page'>
                <LoaderOverlay isLoading={this.props.store.isLoading} />
                <PageTitle text={this.strings.title}/>
                <div className='content'>
                    <PINInput store={this.props.store}
                              submitClicked={this.state.submitClicked}/>
                    <CreatePasswordContainer passwordStore={this.props.store.passwordStore}
                                             onEnterPress={this.props.store.confirmPin}
                                             isMobile={true}/>
                    {invalidCode}
                </div>
                <div className='footer'>
                    <Button className='button'
                            onClick={submit}>
                        {this.strings.registerButtonLabel}
                    </Button>
                </div>
                <InformationalModal isOpen={this.props.store.isModalOpen}
                                    onClose={this.props.store.closeModal}
                                    title={this.props.store.modalTitle}
                                    content={this.props.store.modalBody} />
            </Slide>
        )
    }
}

export default PinConfirmation;

