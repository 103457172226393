import { HttpService, IApiConfigurationService, IHttpService } from '@swipejobs/frontend-comms-services';
import TokenService from './TokenService';
import {AxiosService} from "./axiosService";

export class ReferralService {

    constructor(private httpService: AxiosService) {
    }


    async createReferral(referralCode: string, programId: string, utm_id?: string) {
        try {

            let url = `referrals/referral/${referralCode}?programId=${programId}`;

            if (utm_id) {
                url = `${url}&utm_id=${utm_id}`;
            }

            const response = await this.httpService.post(url, {});

            return response.data;
        } catch (e) {
            // if this one fails just swallow the error
            console.error(e);
        }
    }

}