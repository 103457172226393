import React from 'react';
import {resources} from '../../resources/strings';
import PageTitle from '../../components/PageTitle';
import {WorkerRegistrationStore} from '../../stores/WorkerRegistrationStore';
import Slide from '../../AnimationTransitions/SlideInTransition';
import {
    Button,
    CircularProgress,
    Icon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import {observer} from "mobx-react";

import './styles.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {ManageShiftTimeStore} from "../../stores/ManageShiftTimeStore";
import moment from "moment-timezone";

import {QRActionType} from "../../models/QRActionTypes";

interface IProps {
    workerRegistrationStore: WorkerRegistrationStore;
}

@observer
class ManageShiftTime extends React.Component<IProps>{

    private store: ManageShiftTimeStore;

    constructor(props) {
        super(props);
        this.store = ManageShiftTimeStore.getInstance();
    }

    async componentDidMount() {
        this.props.workerRegistrationStore.showHeader();
        await this.store.init();
    }

    getloader(loader: boolean): JSX.Element {

        if (!loader) {
            return null;
        }

        return (
            <CircularProgress color="primary" className={'loader'}/>
        )
    }

    get shiftDayMonth() {
        const shift = this.store.workerShiftInformation;
        return shift ? moment.tz(shift?.shiftStart, shift?.timeZone).format('dddd, Do MMMM'): null;
    }

    get shiftTime() {
        const shift = this.store.workerShiftInformation;
        return shift ? moment.tz(shift?.shiftStart, shift?.timeZone).format('h:mm a') : null;
    }

    get shiftInformation() {

        if (!this.store.workerShiftInformation) {
            return null;
        }

        const strings = resources.manageShiftTime;

        return (
            <>
                <div className={'sub-title'}>{strings.startTime}</div>
                <div>
                    <div>{this.shiftDayMonth}</div>
                    <div>{this.shiftTime}</div>
                </div>
            </>
        );
    }

    get jobTicketInformation() {


        if (this.store.isLoading || !this.store.workerShiftInformation) {
            return null;
        }

        return (
            <>
                <div>{this.store.workerShiftInformation.jobSiteAddress?.formattedAddress}</div>
                <div> {this.store.jobTitle?.title} </div>
                <div className={'seperator'}>
                    {this.shiftInformation}
                </div>
            </>
        )
    }

    get submittedIcon() {

        if (!this.store.isSubmitted) {
            return null;
        }

        const strings = resources.manageShiftTime;

        return (
            <div className={'submitted-container'}>
                <Icon className={'submitted-icon'}>
                    <CheckCircleIcon/>
                </Icon>
                <span className={'submitted-text'}>{strings.submitted}</span>
            </div>
        )
    }

    get previousActions() {

        const shiftInfo = this.store.workerShiftInformation;
        const strings = resources.manageShiftTime;

        if (!shiftInfo?.checkInInfo?.checkedIn && !shiftInfo?.checkOutInfo?.checkedOut) {
            return null;
        }

        const checkInInfo = () => {

            if (!shiftInfo?.checkInInfo?.checkinTime) {
                return null;
            }

            return (
                <TableRow>
                    <TableCell align={'center'}>{moment.tz(shiftInfo?.checkInInfo?.checkinTime, shiftInfo.timeZone).format('h:mm a')}</TableCell>
                    <TableCell align={'center'}>{strings.checkIn}</TableCell>
                </TableRow>
            );
        };

        const checkOutInfo = () => {

            if (!shiftInfo?.checkOutInfo?.checkoutTime) {
                return null;
            }

            return (
                <TableRow>
                    <TableCell align={'center'}>{moment.tz(shiftInfo?.checkOutInfo.checkoutTime, shiftInfo.timeZone).format('h:mm a')}</TableCell>
                    <TableCell align={'center'}>{strings.checkOut}</TableCell>
                </TableRow>
            );
        };

        return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell  align={'center'}>{strings.timestamp}</TableCell>
                    <TableCell  align={'center'}>{strings.action}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {checkInInfo()}
                {checkOutInfo()}
            </TableBody>
        </Table>
        );
    }

    get checkInButton() {

        if (this.store.isLoading ||
            this.store.error ||
            this.store.isSubmitted ||
            this.store.workerShiftInformation?.checkInInfo?.checkedIn) {
            return null;
        }

        const strings = resources.manageShiftTime;

        return (
            <Button className='button'
                    hidden={true}
                    disabled={this.store.disableCheckin}
                    onClick={() => this.store.changedStatusClicked(QRActionType.CheckIn)}>
                {strings.checkIn}
            </Button>
        )
    }

    get checkOutButton() {

        if(this.store.hideCheckOutButton) {
            return null;
        }

        const strings = resources.manageShiftTime;

        return (
            <Button className='button'
                    hidden={true}
                    disabled={this.store.disableCheckin}
                    onClick={() => this.store.changedStatusClicked(QRActionType.CheckOut)}>
                {strings.checkOut}
            </Button>
        )
    }

    get displayError() {

        if (this.store.isLoading || !this.store.error) {
            return null;
        }

        const strings = resources.manageShiftTime;
        const errorLabel = strings[this.store.error]
        return <div className={'error-message'}>{errorLabel}</div>;
    }

    get displayEntryMessage() {

        if (!this.store.workerShiftInformation?.checkOutInfo?.checkoutTime) {
            return null;
        }

        const strings = resources.manageShiftTime;
        return <div className={'enter-time'}>{strings.enterTimeInApp}</div>
    }

    render() {

        const strings = resources.manageShiftTime;

        return(
            <Slide in className='standard manage-shift-time'>
                <PageTitle text={strings.title}/>
                <div className={'checkin-wrapper'}>
                    <div className={'checkin-container'}>
                        {this.getloader(this.store.isLoading)}
                        {this.jobTicketInformation}
                        {this.previousActions}
                        {this.displayError}
                        {this.getloader(this.store.isUpdatingStatus)}
                        {this.displayEntryMessage}
                    </div>
                    <div className={'footer'}>
                        {this.submittedIcon}
                        {this.checkInButton}
                        {this.checkOutButton}
                    </div>
                </div>
            </Slide>
        )
    }
}

export default ManageShiftTime;
