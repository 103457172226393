import moment from 'moment-timezone';
import { JobTitle } from '../models/JobTitle';
import { QRShiftInfo } from '../models/QRRecords';
import { JobTitleService } from '../services/JobTitleService';
import {TimeAttendanceService} from "../services/TimeAttendenceService";
import {
    action,
    computed,
    observable
} from 'mobx';
import {
    JobTicketQRInformation,
    QRManageError
} from '../models/JobTicketQRInformation';
import {QRActionType} from "../models/QRActionTypes";

export const qrCodeExpiredError = 'ERR2401';

export class ManageShiftTimeStore {

    public static instance: ManageShiftTimeStore = null;

    private timeAttendanceService: TimeAttendanceService;
    private jobTitleService: JobTitleService;

    @observable public workerShiftInformation: QRShiftInfo;

    @observable public jobTitle: JobTitle;

    @observable error: QRManageError = null;

    @observable isLoading: boolean = false;
    @observable isUpdatingStatus: boolean = false;
    @observable isLoadingHours: boolean = false;

    @observable isSubmitted: boolean = false;

    private queryId: string = '';

    static getInstance() {
        if (this.instance === null) {
            this.instance = new ManageShiftTimeStore();
        }
        return this.instance;
    }

    constructor() {
        this.timeAttendanceService = new TimeAttendanceService();
        this.jobTitleService = new JobTitleService();
    }

    reset() {
        this.clearErrors();
        this.workerShiftInformation = null;
    }

    private getIdFromUrl(): string {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');

        if (id && id?.length === 36) {
            return id;
        }

        return null;
    }

    async init() {
        this.reset();
        this.isLoading = true;
        const signInId = this.getIdFromUrl();

        if (signInId) {
            this.queryId = signInId;
            const shouldProceed = await this.loadTicketInformation();

            if (!shouldProceed) {
                this.isLoading = false;
                return;
            }

            await this.getShiftInformation();
            await this.fetchJobTitle();
        } else {
            this.error = !signInId ? QRManageError.noIdFoundError : null;
        }

        this.isLoading = false;
    }

    @action.bound
    async loadTicketInformation(): Promise<boolean> {

        try {
            const jobTicketInformation = await this.timeAttendanceService.fetchQRInformation(this.queryId);

            const expiryTime = moment(jobTicketInformation?.expiryTime);
            const currentTime = moment(jobTicketInformation?.currentInstant);

            if (expiryTime.isBefore(currentTime)) {
                this.error = QRManageError.qrExpired;
                return false;
            } else {
                this.clearErrors();
                return true;
            }

        } catch (error) {
            this.error = QRManageError.loadingError;
            return false;
        }
    }

    @action.bound
    clearErrors() {
        this.error = null;
    }

    @computed
    get disableCheckin(): boolean {
        return !!this.error || this.isUpdatingStatus;
    }

    @action.bound
    async changedStatusClicked(actionType: QRActionType) {

        const createEntry = async (position?: Position) => {

            this.isUpdatingStatus = true;
            this.clearErrors();

            try {
                await this.timeAttendanceService.createPersonalEntry({
                    shiftId: this.workerShiftInformation.shiftId,
                    qrCodeId: this.queryId,
                    scanLatitude: position?.coords?.latitude,
                    scanLongitude: position?.coords?.longitude,
                    actionType: actionType
                });

                this.isSubmitted = true;

                await this.getShiftInformation();

            } catch (error) {
                this.error = QRManageError.submissionError;
            }

            this.isUpdatingStatus = false;
        }

        this.isUpdatingStatus = true;

        await navigator.geolocation.getCurrentPosition(createEntry,
            (error) => {

                if(error?.code === 1) { //User denied Geolocation
                    this.error = QRManageError.locationNotProvided;
                } else {
                    createEntry();
                }
            });
    }

    @computed
    get hideCheckOutButton() {

        return (
            this.isLoading ||
            this.error ||
            this.isSubmitted ||
            this.workerShiftInformation?.checkOutInfo?.checkedOut ||
            !this.workerShiftInformation?.checkInInfo?.checkedIn
        );
    }

    async getShiftInformation(): Promise<void> {

        try {
            this.workerShiftInformation = await this.timeAttendanceService.fetchShiftInformation(this.queryId);
        } catch (error) {

            if (error.response?.errorCode === qrCodeExpiredError) {
                this.error = QRManageError.qrExpired;
            } else {
                this.error = QRManageError.dispatchError;
            }

            return null;
        }
    }

    async fetchJobTitle(): Promise<void> {
        try {
            const jobTitles = await this.jobTitleService.getJobTitlesByIdsAsync([this.workerShiftInformation?.jobTitleId]);
            this.jobTitle = jobTitles[0];
        } catch (error) {}
    }
}