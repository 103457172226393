import React from 'react';
import Register from '../../components/Register/';
import './landingPage1.scss';
import PageTitle from '../../components/PageTitle';
import {WorkerRegistrationStore} from "../../stores/WorkerRegistrationStore";
import { ClientTrackingNames } from '../../models/ClientTracking';
interface IProps {
    workerRegistrationStore: WorkerRegistrationStore;
}

function LandingPage1(props: IProps){
    props.workerRegistrationStore.showHeader();

    React.useEffect(() =>
            props.workerRegistrationStore.clientTracking.trackRender(
                ClientTrackingNames.Landing,
                {page: 1}),
        []
    );

    return(
        <div className='landing-page-1 standard'>
            <PageTitle text={"Apply now to get started"}/>
            <div className='content'>
                <p>To complete you application for a role with us please enter your details below.</p>
                <p></p>
                <p>This mobile app will allow you to complete your application</p>

            </div>
            <Register store={props.workerRegistrationStore.registrationStore}
                      workerRegistrationStore={props.workerRegistrationStore}/>
        </div>
    );
}

export default LandingPage1;
