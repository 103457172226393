import * as React from 'react';
import {observer} from 'mobx-react';
import {
    InputContainer,
    InputRow,
    TextInput,
    ListElement,
    ErrorMessage,
    PasswordRulesList,
    InvalidRule,
    InvalidIcon,
    IconButtonStyled
} from './index-styled-compontents';
import _ from 'lodash';
import Icon from '@material-ui/core/Icon/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { PasswordStore } from '../../stores/PasswordStore';
import { resources } from '../../resources/strings';
import './styles.scss';

interface IProps {
    passwordStore: PasswordStore;
    onEnterPress?: () => void;
    isMobile?: boolean;
}

@observer
export default class CreatePasswordContainer extends React.Component<IProps>{
    passwordStore: PasswordStore;

    protected confirmPasswordInput;

    constructor(props: IProps) {
        super(props);
        this.passwordStore = props.passwordStore;
        this.confirmPasswordInput = React.createRef();
    };

    private getResources = (key: string): string => {
         return resources.createPassword[key];
    };

    getInvalidIcon = (validator: boolean): JSX.Element => {
        return validator && <Icon style={InvalidIcon}>cancel</Icon>;
    };

    getErrorMessage = (text: string, validator: boolean): JSX.Element => {
        return validator && <ErrorMessage>{text}</ErrorMessage>;
    };

    updatePassword = (event: { target: { value: string } }): void => {
        const password = event.target.value;
        this.passwordStore.setPassword(password);
        this.passwordStore.validatePassword();
        this.passwordStore.validateConfirmPassword();
    };

    updateConfirmPassword = (event: { target: { value: string } }): void => {
        const confirmPassword = event.target.value;
        this.passwordStore.setConfirmPassword(confirmPassword);
        this.passwordStore.validateConfirmPassword();
    };

    getInputAdornment = (showPassword: boolean, changePasswordVisibility: () => void): JSX.Element => {
        const handleClickShowPassword = (): void => {
            changePasswordVisibility();
        };

        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
            event.preventDefault();
        };

        return (
            <InputAdornment position={'end'}>
                <IconButtonStyled
                    aria-label={'toggle password visibility'}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButtonStyled>
            </InputAdornment>
        );
    };

    getPasswordInput = (): JSX.Element => {
        const passwordDoesntMeetRequirements =
            this.passwordStore.wasPasswordSubmitted ?
                !this.passwordStore.isPasswordValid.valid : false;

        const passwordPlaceholder = this.getResources('passwordPlaceholder');
        const passwordErrorMessage = this.getResources('passwordErrorMessage');

        const inputAdornment = this.getInputAdornment(this.passwordStore.showPassword, this.passwordStore.changePasswordVisibility);
        const errorMessage = this.getErrorMessage(passwordErrorMessage, passwordDoesntMeetRequirements);
        const invalidIcon = !this.props.isMobile && this.getInvalidIcon(passwordDoesntMeetRequirements);

        const onTabKeyPress = (event): void => {
            if(event.key === 'Tab' || event.keyCode === 9){
                event.stopPropagation();
                event.preventDefault();
                this.confirmPasswordInput.current.focus();
            }
        };

        const theme : InputTheme = {
            isMobile: this.props.isMobile,
            errorPassword: passwordDoesntMeetRequirements
        };

        return (
            <InputContainer
                isMobile={this.props.isMobile}
                isErrorDisplay={this.props.isMobile && passwordDoesntMeetRequirements}
            >
                <InputRow>
                    <TextInput
                        className='outlined-basic'
                        disableUnderline={true}
                        type={this.passwordStore.showPassword ? 'text' : 'password'}
                        onChange={this.updatePassword}
                        placeholder={passwordPlaceholder}
                        onKeyDown={onTabKeyPress}
                        endAdornment={inputAdornment}
                        theme={theme}
                        error={passwordDoesntMeetRequirements}
                    />
                    {invalidIcon}
                </InputRow>
                {errorMessage}
            </InputContainer>
        );
    };

    getConfirmPasswordInput = (): JSX.Element => {
        const passwordsDontMatch = this.passwordStore.wasPasswordSubmitted ? !this.passwordStore.passwordsMatch : false;

        const confirmPasswordPlaceholder = this.getResources('confirmPasswordPlaceholder');
        const confirmationPasswordErrorMessage = this.getResources('confirmationPasswordErrorMessage');

        const inputAdornment = this.props.isMobile &&
            this.getInputAdornment(this.passwordStore.showConfirmationPassword, this.passwordStore.changeConfirmationPasswordVisibility);
        const errorMessage = this.getErrorMessage(confirmationPasswordErrorMessage, passwordsDontMatch);
        const invalidIcon = !this.props.isMobile && this.getInvalidIcon(passwordsDontMatch);

        const onKeyEnterPress = async (event) => {
            if(event.key === 'Enter' && this.props.onEnterPress){
                await this.props.onEnterPress();
            }
        };

        const theme : InputTheme = {
            isMobile: this.props.isMobile,
            errorPassword: passwordsDontMatch
        };

        return (
            <InputContainer
                isMobile={this.props.isMobile}
                isErrorDisplay={this.props.isMobile && passwordsDontMatch}
            >
                <InputRow>
                    <TextInput
                        className='outlined-basic'
                        disableUnderline={true}
                        type={this.passwordStore.showConfirmationPassword && this.props.isMobile ? 'text' : 'password'}
                        onChange={this.updateConfirmPassword}
                        placeholder={confirmPasswordPlaceholder}
                        onKeyPress={onKeyEnterPress}
                        inputRef={this.confirmPasswordInput}
                        endAdornment={inputAdornment}
                        theme={theme}
                        error={passwordsDontMatch}
                    />
                    {invalidIcon}
                </InputRow>
                {errorMessage}
            </InputContainer>
        );
    };

    getPasswordRules = (): JSX.Element => {
        const rules = [];

        const moreThanEightRule = this.getResources('moreThanEightRule');
        const specialCharacterRule = this.getResources('specialCharacterRule');
        const lowerCaseRule = this.getResources('lowerCaseRule');
        const numberRule = this.getResources('numberRule');

        rules.push(moreThanEightRule);
        rules.push(specialCharacterRule);
        rules.push(lowerCaseRule);
        rules.push(numberRule);

        const listElements = _.map(rules, (rule: string, index: number) => {
            const validation =
                this.passwordStore.wasPasswordSubmitted ?
                    !this.passwordStore.isPasswordValid.valid &&
                    this.passwordStore.isPasswordValid.regexFailedPositions.includes(index) :
                    false ;
            return <ListElement key={index}
                                style={validation && InvalidRule || {}}>{rule}</ListElement>
        });

        return (
            <PasswordRulesList>
                {listElements}
            </PasswordRulesList>
        );
    };


    render () {
        const passwordMessage = this.getResources('passwordMessage');

        const passwordInput = this.getPasswordInput();
        const confirmPasswordInput = this.getConfirmPasswordInput();
        const passwordRulesList = this.getPasswordRules();

        return (
          <div className='create-password-container'>
              {passwordMessage}
              {passwordRulesList}
              {passwordInput}
              {confirmPasswordInput}
          </div>
        );
    };
}

type InputTheme = {
    isMobile?: boolean;
    errorPassword?: boolean;
}
